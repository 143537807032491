import React from 'react'
import './Manager.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import Dashboard from '../../components/Dashboard/Dashboard'

const Manager = () => {
    return (
        <div className='manager'>
            <Sidebar />
            <div className="managercontainer">
                <div className='navbarcontainer'>
                    <Navbar />
                </div>

                <div className='dashboard'>
                    <Dashboard />
                </div>
                
            </div>
        </div>
    )
}

export default Manager