import React, { useState, useEffect } from "react";
import { read, utils } from 'xlsx';
import * as XLSX from 'xlsx';
import { /* submitScoreCardData, */ filteredCommissionAfterScorecard, getFilterdByDate } from '../../helper/helper'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import './ScoreCard.scss'
import { cisCommisson, extractPhoneNumber, employeeCommissionCalculate, /* calculateStoreCommissionAfterCisCommission, hasCommonWord */ } from '../../components/ReusableFunctions'
import ScorecardCommission, { /* formatDate */ } from '../../components/ScorecardCommission'
import DateFilter from '../../components/DateFilter';
/* import { is } from "date-fns/locale";
import { common } from "@mui/material/colors"; */
import { CSVLink } from "react-csv";
import { format, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import RefundFilter from '../../components/RefundFilter'

const ScoreCard = () => {
  const [pres, setPres] = useState([]);
  const [cisdata, setCisdata] = useState([]);
  const [showComplieBtn, setShowComplieBtn] = useState(false);
  const [cisArray, setCisArray] = useState([]);
  const [misMatchData, setMisMatchData] = useState([]);
  const [sameMPostRemove, setSameMPostRemove] = useState([]);
  /* const [hupClawback, setHupClawback] = useState([])
  const [tabPrice, setTabPrice] = useState([])
  const [totalActScore, setTotalActScore] = useState([])
  const [totalScoreAddon, setTotalScoreAddon] = useState([]) */
  const [totalClawbacks, setTotalClawbacks] = useState([])
  const [totalScorecard, setTotalScorecard] = useState([])
  /* const [totalDectandClawback, setTotalDectandClawback] = useState([]);
  const [totalFeatureRemove, setTotalFeatureRemove] = useState([]); */
  const [target, setTarget] = useState([]);
  const [currentMandY, setCurrentMandY] = useState([]);
  const [lastFiveMonthsDates, setLastFiveMonthsDates] = useState({});
  const [prevMonthCIS, setPrevMonthCIS] = useState({});
  /* const [prePaid2Activations, setPrePaid2Activations] = useState([]); */
  const [finalCommissionDownload, setFinalCommissionDownload] = useState([]);
  const [notUploadedFile, setNotUploadedFile] = useState([])
  const [refundData, setRefundData] = useState([])

  useEffect(() => {
    const monthName = currentMandY[0];
    const year = currentMandY[1];
    const monthNumber = new Date(Date.parse(monthName + ' 1, ' + year)).getMonth() + 1;

    // Format the date in yyyy-mm-dd format
    const firstDayDate = `${year}-${monthNumber.toString().padStart(2, '0')}-02`;


    const today = new Date(firstDayDate);

    // Object to store first and last dates of the last five months
    const dates = {};

    // Loop to calculate first and last dates of last five months
    for (let i = 1; i <= 1; i++) {
      const date = addMonths(today, -i); // Subtract i months from today

      // Get the first day of the month
      const firstDayOfMonth = startOfMonth(date);

      // Get the last day of the month
      const lastDayOfMonth = endOfMonth(date);

      // Format dates in desired format (e.g., 'yyyy-MM-dd')
      const formattedFirstDay = format(firstDayOfMonth, 'yyyy-MM-dd');
      const formattedLastDay = format(lastDayOfMonth, 'yyyy-MM-dd');

      // Assign formatted dates to the corresponding month
      dates[`month${i}`] = {
        firstDay: formattedFirstDay,
        lastDay: formattedLastDay,
      };
    }

    // Set the state with the calculated dates
    setLastFiveMonthsDates(dates);

  }, [currentMandY]); // Empty dependency array to run effect only once

  useEffect(() => {

    // Function to call the API with each date
    const fetchDataForDate = async (startDate, endDate, monthKey) => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        let { data } = await getFilterdByDate({ startDate, endDate });

        setPrevMonthCIS(prevData => ({
          ...prevData,
          [monthKey]: data
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the API for each date in lastFiveMonthsDates
    for (const monthKey in lastFiveMonthsDates) {
      const { firstDay, lastDay } = lastFiveMonthsDates[monthKey];
      fetchDataForDate(firstDay, lastDay, monthKey); // You can call the API with firstDay, lastDay, or both as needed

    }
  }, [lastFiveMonthsDates]); // Dependency on lastFiveMonthsDates to trigger the effect when it changes




  /* Read the Score card file for current month for example if today is 01 Feb 2024 then current file will be January score card */
  async function readactivationFile(event) {
    let file = event.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f, { sheetRows: 100000 }); // parse the array buffer
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const data = utils.sheet_to_json(ws, { raw: false }); // generate objects
    setPres(data);
  }


  /* get uploaded CIS data for current month */
  const updateTableData = (data) => {
    setCisdata(data);
  };

  const refundCisData = (data) => {
    setRefundData(data)
  };

  /* function mergeObjects(...objs) {
    const result = {};

    // Iterate over each object
    objs.forEach(obj => {
      // Iterate over each key in the object
      Object.keys(obj).forEach(key => {
        // If the key doesn't exist in the result object, initialize it
        if (!result.hasOwnProperty(key)) {
          result[key] = { ...obj[key] };
        } else {
          // If the key exists, accumulate the values
          Object.keys(obj[key]).forEach(innerKey => {
            result[key][innerKey] = (result[key][innerKey] || 0) + obj[key][innerKey];
          });
        }
      });
    });

    return result;
  } */
  function addScoreCardValues(...arrays) {
    let result = 0;

    arrays.forEach(arr => {
      arr.forEach(val => {
        return result += val.commission
      })
    })
    return result;
  }
  function dateDiffInDays(date1, date2) {

    let date11 = new Date(date1);
    let date22 = new Date(date2);
    let timeDifference = date11 - date22;
    return timeDifference / (1000 * 60 * 60 * 24);
  }
  function calculateHupMargin(computedCustomerList) {
    let hupMargin = 0;
    for (let array = 0; array < computedCustomerList.length; array++) {
      if (computedCustomerList[array].commission * 0.05 < 20 && computedCustomerList[array].commission !== undefined) {
        hupMargin += 20;
      } else if (computedCustomerList[array].commission * 0.05 > 100 && computedCustomerList[array].commission !== undefined) {
        hupMargin += 100;
      } else if (computedCustomerList[array].commission !== undefined) {
        hupMargin += computedCustomerList[array].commission * 0.05;
      }
    }
    return hupMargin;
  }

  /* extract score card activation and other lists for current month */
  async function totalCommission() {
    let cisDataNew = [];

    cisdata.forEach(obj => {
      let exist = refundData.find(refund => refund.originalInvoice === obj.saleInvoice)
      if (!exist) {
        cisDataNew.push(obj)
      }
    })

    const cisCommissonList = cisCommisson(cisDataNew);
    const prevMonthforHupRemoval = cisCommisson(prevMonthCIS.month1);

    cisCommissonList.forEach(item1 => {
      if (item1.type === "Hup") {
        let exist = prevMonthforHupRemoval.find(item2 => item2.phonenumber === item1.phonenumber);
        if (exist) {
          let date1 = new Date(item1.date);
          let date2 = new Date(exist.date);
          let timeDifference = date1 - date2;
          let daysDifference = timeDifference / (1000 * 60 * 60 * 24);

          console.log(daysDifference)
        }
      }
    })


    let newArray = [];

    // Iterate through the array
    for (let i = 0; i < cisCommissonList.length; i++) {
      // Check if the object has a phoneNumber property and type is "Post without device"
      if (cisCommissonList[i].type === "Postpaid without device" && cisCommissonList[i].phonenumber) {
        // Find the matching hup object with the same phoneNumber
        const matchingHupObject = cisCommissonList.find(obj => obj.type === "Hup" && obj.phonenumber === cisCommissonList[i].phonenumber);

        // If a matching hup object is found
        if (matchingHupObject) {
          const daysDiff = dateDiffInDays(cisCommissonList[i].date, matchingHupObject.date);
          // If the difference is less than 30 days, remove both objects and replace with a new one
          if (daysDiff < 30) {
            const commAddonSum = cisCommissonList[i].addonCommission + matchingHupObject.addonCommission;

            // Create a new object with the combined commission
            const newObj = {
              accountNumber: cisCommissonList[i].accountNumber,
              addonCommission: commAddonSum,
              amountYouPaid: cisCommissonList[i].amountYouPaid,
              cisstoreId: cisCommissonList[i].cisstoreId,
              date: cisCommissonList[i].date,
              deposit: cisCommissonList[i].deposit,
              name: cisCommissonList[i].name,
              phonenumber: cisCommissonList[i].phonenumber,
              planCommission: cisCommissonList[i].planCommission,
              ppp: cisCommissonList[i].ppp,
              retailHandset: matchingHupObject.retailHandset,
              user: cisCommissonList[i].user,
              type: "Combined(postpaid/no HUP Commission but with Tab)",
            };

            // Insert the new object into the new array
            newArray.push(newObj);
            // Skip the matching hup object since it's already processed
            const matchingIndex = cisCommissonList.indexOf(matchingHupObject);
            if (matchingIndex > -1) {
              cisCommissonList.splice(matchingIndex, 1);
            }
          } else {
            // If the difference is not less than 30 days, simply push both objects into the new array
            newArray.push(cisCommissonList[i]);
            newArray.push(matchingHupObject);
          }
        } else {
          // If no matching hup object is found, simply push the current object into the new array
          newArray.push(cisCommissonList[i]);
        }
      } else {
        // If the object doesn't meet the condition, simply push it into the new array
        newArray.push(cisCommissonList[i]);
      }
    }



    setCisArray(newArray);
    const {
      prepaid,
      postFeatures,
      preFeatures,
      postPaidDeactivated,
      /* postAfterSameMonth,
      postPaidFeatureRemove,
      hardwareUpgradeFinal,
      scoreCardDeact, */
      prePaidFeaturesRemove,
      tabHandsetClawbacks,
      activationClawbacks,
      hardwareUpgradesClawbacks,
      featuresClawbacks,
      tabHandset,
      actForCommission,
      prePaid2Activations,
      postPaidActivations,
      deposits,
      hardwareUpgrades,

    } = await ScorecardCommission(pres);

    const totalClawback = [...activationClawbacks/* , ...hardwareUpgradesClawbacks, ...featuresClawbacks */]
    setTotalClawbacks(totalClawback)
    /* const total = [...postAfterSameMonth, ...prepaid, ...hardwareUpgradeFinal];
    const totalAddons = [...postFeatures, ...preFeatures]
    const totalFeatureRemove = [...featuresClawbacks, ...postPaidFeatureRemove, ...prePaidFeaturesRemove] */
    /* setTotalFeatureRemove(totalFeatureRemove)
    setTotalScoreAddon(totalAddons)
    setTotalActScore(total)
    setTotalDectandClawback(scoreCardDeact)
    setHupClawback(hardwareUpgradesClawbacks)
    setTabPrice(tabHandset)
    setPrePaid2Activations(prePaid2Activations) */
    setTotalScorecard(actForCommission)
    setSameMPostRemove(postPaidDeactivated);
    setShowComplieBtn(true);

    /* filteredObjects is for the fifth month which needs to be reported to freedom */

    /* const filteredObjects = totalClawback.filter(obj => {
      const activationDate = new Date(obj.activationDate);

      return activationDate >= new Date(lastFiveMonthsDates.month4.firstDay) && activationDate <= new Date(lastFiveMonthsDates.month4.lastDay);
    });

    // Create an array for the remaining objects
    const remainingObjects = totalClawback.filter(obj => !filteredObjects.includes(obj))
    setTotalClawbacks(remainingObjects); */

    let postAfterSameMonthList = [];

    postPaidActivations.forEach(val => {

      let exist = postPaidDeactivated.find(data => (data.phonenumber === val.phonenumber && data.name === val.name && data.accountnumber === val.accountnumber));

      if (!exist) {

        postAfterSameMonthList.push(val);
      }

    })

    const stores = postAfterSameMonthList.map(store => store.store)
    const storeList = [];
    stores.forEach(store => {
      let exist = storeList.some(item => item === store);
      if (!exist) {
        storeList.push(store);
      }
    })
    const storeComm = [];
    let count = 0
    storeList.forEach(store => {
      let postPerStore = postPaidActivations.filter(obj => obj.store === store);
      let postDeactivatePerStore = postPaidDeactivated.filter(obj => obj.store === store);
      let prePerStore = prepaid.filter(obj => obj.store === store);
      let pre2PerStore = prePaid2Activations.filter(obj => obj.store === store);
      let actClawbackPerStore = activationClawbacks.filter(obj => obj.store === parseInt(store));
      let featurePerStore = postFeatures.filter(obj => obj.store === store);
      let featureClawbackPerStore = featuresClawbacks.filter(obj => obj.store === store);
      let preFeature = preFeatures?.filter(obj => obj.store === store);
      let preFeatureDeactivate = prePaidFeaturesRemove?.filter(obj => obj.store === store);
      let tabPerStore = tabHandset.filter(obj => obj.store === store);
      let tabClawbackPerStore = tabHandsetClawbacks.filter(obj => obj.store === store);
      let depositPerStore = deposits.filter(obj => obj.store === store);
      let hupPerStore = hardwareUpgrades.filter(obj => obj.store === store);
      let hupClawbackPerStore = hardwareUpgradesClawbacks.filter(obj => obj.store === store);
      let tabMargin = calculateHupMargin(tabPerStore);
      let tabClawbackMargin = calculateHupMargin(tabClawbackPerStore);

      let resultPost = 0;
      postPerStore.map(obj => {
        return resultPost += obj.commission
      })
      let resultPostDeactivate = 0;
      postDeactivatePerStore.map(obj => {
        return resultPostDeactivate -= obj.commission
      })
      const resultPre = addScoreCardValues(
        prePerStore,
        pre2PerStore,
      );

      let resultActafterClawback = 0
      actClawbackPerStore.map(obj => {
        return resultActafterClawback += obj.commission
      })
      let resultFeature = 0
      featurePerStore.map(obj => {
        return resultFeature += obj.commission
      });
      let resultPreAddon = 0;
      preFeature.map(obj => {
        return resultPreAddon += obj.commission
      });
      let resultPreAddonDeactivate = 0;
      preFeatureDeactivate.map(obj => {
        return resultPreAddonDeactivate += obj.commission
      })
      let resultMinusClawback = 0
      featureClawbackPerStore.map(obj => {
        return resultMinusClawback += obj.commission
      });
      let resultTab = 0;
      tabPerStore.map(obj => {
        return resultTab += obj.commission
      });
      let resultTabMinusClawback = 0;
      tabClawbackPerStore.map(obj => {
        return resultTabMinusClawback += obj.commission
      })
      let resultHup = 0;
      hupPerStore.map(obj => {
        return resultHup += obj.commission
      });
      let resultHupMinusClawback = 0;
      hupClawbackPerStore.map(obj => {
        return resultHupMinusClawback += obj.commission
      })
      let resultDeposit = 0;
      depositPerStore.map(obj => {
        return resultDeposit += obj.commission
      })

      count += (resultTab)
      const totalStoreCom = ((resultPost - (resultPostDeactivate * 3)
        + resultPre - resultActafterClawback)
        + (resultFeature - resultMinusClawback)
        + (resultPreAddon - resultPreAddonDeactivate)
        + (resultHup - resultHupMinusClawback))
        + (tabMargin - tabClawbackMargin)

      storeComm[store] = totalStoreCom;

    })
    console.log(storeComm, count);
    return storeComm;

  }

  /* const storeMatch = {
    184401: "VYAREIN",
    184405: "VYAD2IN",
    184408: "VYAMLIN",
    184417: "DAN01IN",
    184420: "VYAWLIN",
    184423: "TCPNFIN",
    184425: "DTWICIN",
    184427: "BARRIIN",
    184428: "OSHWTIN",
    184432: "LAKESIN",
    184433: "VYACBIN",
    184434: "VYOSHIN"
  }
  const storeNameList = {
    OSHWTIN: "Lake Vista Plaza",
    LAKESIN: "Lakeshore & Islington",
    VYAD2IN: "Center Mall Barton",
    TCPNFIN: "Town Center Plaza",
    VYACBIN: "Cedarbrae Mall",
    BARRIIN: "Barrie",
    VYAREIN: "Rexdale & Islington",
    DTWICIN: "Briarfield Shopping Centre",
    DAN01IN: "Danforth & Woodbine",
    VYOSHIN: "Big Bay",
    VYAMLIN: "Markham & Lawrence",
    VYAWLIN: "Weston & Lawrence",
  }; */

  function sortDataByStoreInvoice(data) {
    let sortedData = {};

    data.forEach(item => {
      if (!sortedData[item.storeInvoice]) {
        sortedData[item.storeInvoice] = [];
      }
      sortedData[item.storeInvoice].push(item);
    });

    return sortedData;
  }

  function addValues(...arrays) {
    const result = {};

    arrays.forEach(array => {
      for (let email in array) {
        if (!result.hasOwnProperty(email)) {
          result[email] = { "post": 0, "pre": 0, "addon": 0, "hup": 0, "ap": 0 };
        }
        result[email].post += array[email].post;
        result[email].pre += array[email].pre;
        result[email].addon += array[email].addon;
        result[email].hup += array[email].hup;
        result[email].ap += array[email].ap;
      }
    });
    return result;
  }

  function subtractValues(array4, mergedArray123, prevMonthCis, prevMonthCis3, prevMonthCis4, sameMonthCisListObj) {

    const result = [];

    for (let email in array4) {
      if (!result.hasOwnProperty(email)) {
        result[email] = { "post": 0, "pre": 0, "addon": 0, "hup": 0, "ap": 0, 'total': 0 };
      }
      // Subtract the sum of arrays 1, 2, and 3 from array 4
      result[email].post = array4[email].post - mergedArray123[email].post;
      result[email].pre = array4[email].pre - mergedArray123[email].pre;
      result[email].addon = array4[email].addon - mergedArray123[email].addon;
      result[email].hup = array4[email].hup - mergedArray123[email].hup;
      result[email].ap = array4[email].ap - mergedArray123[email].ap;
      result[email].total = result[email].post + result[email].pre + result[email].addon + result[email].hup + result[email].ap
      result[email].prevMonth = prevMonthCis[email] ? prevMonthCis[email] : ''
      result[email].prevMonth2 = prevMonthCis3[email] ? prevMonthCis3[email] : ''
      result[email].prevMonth3 = prevMonthCis4[email] ? prevMonthCis4[email] : ''
      result[email].sameMonth = sameMonthCisListObj[email] ? sameMonthCisListObj[email] : '';
    }

    return result;
  }
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([['Employee', 'Commission']]);


    for (const emails in finalCommissionDownload) {
      const email = emails;
      let total = 0;
      for (const location in finalCommissionDownload[email]) {
        total += finalCommissionDownload[email][location].post + finalCommissionDownload[email][location].pre + finalCommissionDownload[email][location].addon + finalCommissionDownload[email][location].hup + finalCommissionDownload[email][location].ap;
      }
      XLSX.utils.sheet_add_aoa(ws, [[email, total.toFixed(3)]], { origin: -1 });
    }


    XLSX.utils.book_append_sheet(wb, ws, 'Commission');
    XLSX.writeFile(wb, 'output.xlsx');
  };

  function ScoreCardCompile(cisdata, totalScorecard) {

    const notUploaded = [];
    totalScorecard.forEach(item2 => {
      let exist = cisdata.find(item => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
        || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))
      if (!exist) {
        notUploaded.push(item2)
      }
    })


    const employees = cisdata.map(store => store.user)
    const employeesList = [];
    employees.forEach(employees => {
      let exist = employeesList.some(item => item === employees);
      if (!exist) {
        employeesList.push(employees);
      }
    })

    const employeesAct = []
    const cisemployeesAct = []
    const cisweirdList = []
    const storeActPrevMonth = [];
    const storeActPrevMonth3 = [];
    const storeActPrevMonth4 = [];
    const cisstoreActPrevMonth = [];
    const cisstoreActPrevMonth3 = [];
    const cisstoreActPrevMonth4 = [];
    employeesList.forEach(employees => {
      let employeesActData = cisdata.filter(obj => obj.user === employees);
      let StorePrevMonthData = prevMonthCIS?.month1?.filter(obj => obj.user === employees);
      let storeDataMonth3 = prevMonthCIS?.month2?.filter(obj => obj.user === employees);
      let storeDataMonth4 = prevMonthCIS?.month3?.filter(obj => obj.user === employees);
      employeesAct[employees] = employeesActData
      storeActPrevMonth[employees] = StorePrevMonthData
      storeActPrevMonth3[employees] = storeDataMonth3
      storeActPrevMonth4[employees] = storeDataMonth4

    })

    for (let email in employeesAct) {
      let scoreList = []
      let weirdList = []
      const matchResultPrevMonth = [];
      const matchResultPrevMonth3 = [];
      const matchResultPrevMonth4 = [];
      storeActPrevMonth[email]?.forEach(item => {
        let exist = totalClawbacks.find(item2 => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
          || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))

        if (exist) {
          matchResultPrevMonth.push(item);
        }

      })
      storeActPrevMonth3[email]?.forEach(item => {
        let exist = totalClawbacks.find(item2 => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
          || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))

        if (exist) {
          matchResultPrevMonth3.push(item);
        }

      })
      storeActPrevMonth4[email]?.forEach(item => {
        let exist = totalClawbacks.find(item2 => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
          || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))

        if (exist) {
          matchResultPrevMonth4.push(item);
        }

      })

      employeesAct[email].forEach(item => {
        let exist = totalScorecard.find(item2 => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
          || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))
        if (exist) {
          scoreList.push(item)
        } else {
          weirdList.push(item);
        }
      })
      cisemployeesAct[email] = scoreList

      cisweirdList[email] = weirdList
      cisstoreActPrevMonth[email] = matchResultPrevMonth
      cisstoreActPrevMonth3[email] = matchResultPrevMonth3
      cisstoreActPrevMonth4[email] = matchResultPrevMonth4

    }
    const cisAfterSMD = []
    const sameMonthCisList = {}
    for (let email in cisemployeesAct) {
      let smdList = []
      let unsmdList = []

      cisemployeesAct[email].forEach(item => {
        let existSMD = sameMPostRemove.find(item2 => item2.phonenumber === extractPhoneNumber(item.phoneNum)?.replace(/\D/g, '')
          || item2.phonenumber === extractPhoneNumber(item.tempPhoneNum)?.replace(/\D/g, ''))

        if (existSMD) {
          smdList.push(item)
        } else {
          unsmdList.push(item);
        }
      })

      cisAfterSMD[email] = unsmdList
      sameMonthCisList[email] = smdList
    }

    let employeeCommission = [];
    let prevMonthCommission = [];
    let prevMonth3Commission = [];
    let prevMonth4Commission = [];
    let prevMonthCis = {};
    let prevMonthCis3 = {};
    let prevMonthCis4 = {};
    let sameMonthCisListObj = {};

    for (let email in cisAfterSMD) {
      let sortedData = sortDataByStoreInvoice(cisAfterSMD[email]);
      let sortedDatacisstoreActPrevMonth = cisstoreActPrevMonth[email];
      let sortedDatacisstoreActPrevMonth3 = cisstoreActPrevMonth3[email];
      let sortedDatacisstoreActPrevMonth4 = cisstoreActPrevMonth4[email];
      let sortedDatacisstoreActsameMonthCisList = sameMonthCisList[email];
      let obj = {};

      for (let store in sortedData) {
        const Data = [];
        target.forEach(item => {
          if (item.username === email && item.storeinvoice === store) {
            Data.push(item)
          }
        })
        const targetData = [];
        Data.map(item => {
          if (targetData.hasOwnProperty("postpaidtarget")) {
            targetData.postpaidtarget += parseInt(item.postpaidtarget);
            targetData.prepaidtarget += parseInt(item.prepaidtarget);
            targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
            targetData.addon += parseInt(item.addon);
            targetData.accessories += parseInt(item.accessories);
            targetData.applecare += parseInt(item.applecare);
            targetData.smssurvey += parseInt(item.smssurvey);
          } else {
            targetData.postpaidtarget = parseInt(item.postpaidtarget);
            targetData.prepaidtarget = parseInt(item.prepaidtarget);
            targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
            targetData.addon = parseInt(item.addon);
            targetData.accessories = parseInt(item.accessories);
            targetData.applecare = parseInt(item.applecare);
            targetData.smssurvey = parseInt(item.smssurvey);
          }
          return targetData;
        })

        obj[store] = employeeCommissionCalculate(sortedData[store], targetData);
      }
      let result = { "post": 0, "pre": 0, "addon": 0, "hup": 0, "ap": 0 };;
      Object.values(obj).forEach(obj => {
        result.post += obj.post;
        result.pre += obj.pre;
        result.addon += obj.addon;
        result.hup += obj.hup
        result.ap += obj.ap
      })

      employeeCommission[email] = result;

      prevMonthCommission[email] = employeeCommissionCalculate(sortedDatacisstoreActPrevMonth, '');
      prevMonth3Commission[email] = employeeCommissionCalculate(sortedDatacisstoreActPrevMonth3, '', 0.75);
      prevMonth4Commission[email] = employeeCommissionCalculate(sortedDatacisstoreActPrevMonth4, '', 0.50);

      prevMonthCis[email] = sortedDatacisstoreActPrevMonth;
      prevMonthCis3[email] = sortedDatacisstoreActPrevMonth3;
      prevMonthCis4[email] = sortedDatacisstoreActPrevMonth4;
      sameMonthCisListObj[email] = sortedDatacisstoreActsameMonthCisList;

    }

    const mergedArray123 = addValues(prevMonthCommission, prevMonth3Commission, prevMonth4Commission);
    const finalResult = subtractValues(employeeCommission, mergedArray123, prevMonthCis, prevMonthCis3, prevMonthCis4, sameMonthCisListObj);
    console.log(finalResult);
    return [finalResult, notUploaded];
  }

  function filterNonMatchingNumbers(array1, array2) {
    return array1.filter(obj1 => !array2.some(obj2 => extractPhoneNumber(obj1.phoneNum)?.replace(/\D/g, '') === obj2.phonenumber && obj1.customerName === obj2.name));
  }

  function storeCommissionFromCis(computedCustomerList) {
    let prepaidCommission = 0;
    let postpaidCommission = 0;
    let hupCommission = 0;
    let addonCommission = 0;
    let hupMargin = 0;
    let deposit = 0;
    let tabHandset = 0;
    let spiffs = 0;

    for (let array = 0; array < computedCustomerList.length; array++) {
      if (computedCustomerList[array].type === "Prepaid") {
        prepaidCommission += computedCustomerList[array].planCommission;
      } else if (computedCustomerList[array].type === "Postpaid with device" || computedCustomerList[array].type === "Postpaid without device") {
        postpaidCommission += computedCustomerList[array].planCommission;
      } else if (computedCustomerList[array].type === "Hup") {
        hupCommission += computedCustomerList[array].planCommission;
      }


      if (computedCustomerList[array].retailHandset * 0.05 < 20 && computedCustomerList[array].retailHandset !== undefined) {
        hupMargin += 20;
      } else if (computedCustomerList[array].retailHandset * 0.05 > 100 && computedCustomerList[array].retailHandset !== undefined) {
        hupMargin += 100;
      } else if (computedCustomerList[array].retailHandset !== undefined) {
        hupMargin += computedCustomerList[array].retailHandset * 0.05;
      }

      if (computedCustomerList[array].deposit !== "Not Required") {
        deposit += parseFloat(computedCustomerList[array].deposit);

      }
      if (computedCustomerList[array].addonCommission !== undefined) {
        addonCommission += computedCustomerList[array].addonCommission
      }
      /* if((computedCustomerList[array].addonCommission === 0 || computedCustomerList[array].addonCommission === "") && computedCustomerList[array].ppp === 'Accepted' ) {
          addonCommission += 27
      } */
      if (computedCustomerList[array].spiff !== undefined) {
        spiffs += computedCustomerList[array].spiff;
      }
      if (computedCustomerList[array].retailHandset !== undefined) {
        tabHandset += computedCustomerList[array].retailHandset;
      }
    }

    return { prepaidCommission, postpaidCommission, hupCommission, addonCommission, hupMargin, deposit, tabHandset, spiffs }
  }

  function mismatchScoreData(cisArray, pres) {

    /* let files = [];
    for (let i = 0; i < pres.length; i++) {
      if (pres[i].EventType !== "Postpaid Activation MRC 2 yr Contract"
        && pres[i].EventType !== "TAB Boost Fee Amount"
        && pres[i].EventType !== "Post Paid Activation"
        && pres[i].EventType !== "Postpaid Activation 2 yr Contract"
        && pres[i].EventType !== "Postpaid Activation 2 yr Contract"
        && pres[i].EventType !== "TAB Boost Fee Swap Clawback Amount"
        && pres[i].EventType !== "TAB Boost Fee Return Clawback Amount"
        && pres[i].EventType !== "Same Month Postpaid Deactivation MRC 2 yr Contract"
        && pres[i].EventType !== "Gross Prepaid Activation"
        && pres[i].EventType !== "Pre Paid Activation"
        && pres[i].EventType !== "Pre Paid Activation 2nd Top-up"
        && pres[i].EventType !== "TAB Boost Fee"
        && pres[i].EventType !== "Same Month Deactivation"
        && pres[i].EventType !== "Same Month Postpaid Deactivation"
        && pres[i].EventType !== "Same Month Feature Remove"
        && pres[i].EventType !== "Duplicate Adjustment Gross Prepaid Activation"
        && pres[i].EventType !== "Prepaid Activation - No TopUp"
        && pres[i].EventType !== "Gross Prepaid Activation - Buyer Remorse Adjustment"
        && pres[i].EventType !== "TAB Handset Upgrade Clawback"
        && pres[i].EventType !== "Hardware Upgrade Swap Clawback"
        && pres[i].EventType !== "Feature Activation Included"
        && pres[i].EventType !== "Feature Clawback"
        && pres[i].EventType !== "Prepaid Feature Activation Included"
        && pres[i].EventType !== "Feature Add"
        && pres[i].EventType !== "Feature Activation"
        && pres[i].EventType !== "Gross Same Month Prepaid Feature Deactivation"
        && pres[i].EventType !== "TAB Boost Fee Swap Clawback"
        && pres[i].EventType !== "TAB Handset Return Clawback"
        && pres[i].EventType !== "TAB Boost Fee Return Clawback"
        && pres[i].EventType !== "TAB Handset Downgrade Clawback"
        && pres[i].EventType !== "Hardware Upgrade Return Clawback"
        && pres[i].EventType !== "Same Month Feature Deactivation"
        && pres[i].EventType !== "TAB Handset Sidegrade Clawback"
        && pres[i].EventType !== "PAP"
        && pres[i].EventType !== "PAP Clawback"
        && pres[i].EventType !== "PAP Clawback Amount"
        && pres[i].EventType !== "Gross Prepaid Feature Activation"
        && pres[i].EventType !== "Prepaid Feature Activation"
        && pres[i].EventType !== "Activation Clawback - PostPaid Suspend"
        && pres[i].EventType !== "PostPaid Clawback - Suspend"
        && pres[i].EventType !== "Activation Clawback MRC - PostPaid Suspend"
        && pres[i].EventType !== "Pre Paid Activation MRC 3"
        && pres[i].EventType !== "Pre Paid Activation MRC 2"
        && pres[i].EventType !== "Pre Paid Activation 2"
        && pres[i].EventType !== "Feature MRC Clawback"
        && pres[i].EventType !== "Feature Deactivation"
        && pres[i].EventType !== "Pre Paid Activation - No 2nd Top-up"
        && pres[i].EventType !== "Activation Clawback MRC - PostPaid"
        && pres[i].EventType !== "Activation Clawback - PostPaid"
        && pres[i].EventType !== "PostPaid Clawback - Deactivation"
        && pres[i].EventType !== "Postpaid Activation 2 yr Contract Suspend MRC"
        && pres[i].EventType !== "Postpaid Activation 2 yr Contract Suspend"
      ) {
        if (pres[i].EventType === "Post Paid Activation MRC") {
          pres[i].EventValue = parseFloat(pres[i].EventValue) * 3
        }
        if (pres[i].EventType === "Pre Paid Activation MRC") {
          if (pres[i].RatePlan === "By The Year 4G Nationwide Talk") {
            pres[i].EventValue = 25
          } else if (pres[i].RatePlan === "By The Year 4G Nationwide 15GB") {
            pres[i].EventValue = 30
          } else if (pres[i].RatePlan === "By The Year Nationwide 30GB") {
            pres[i].EventValue = 37
          } else if (pres[i].RatePlan === "By The Year 4G Nationwide Talk Text") {
            pres[i].EventValue = 32
          } else {
            pres[i].EventValue = parseFloat(pres[i].EventValue) * 1.34
          }
        }
        if (pres[i].EventType === "Feature Activation MRC" || pres[i].EventType === "Feature Add MRC" || pres[i].EventType === "Prepaid Feature Activation MRC") {
          if (pres[i].RatePlan === "Phone Protection Plan") {
            pres[i].EventValue = 27

          } else {
            pres[i].EventValue = parseFloat(pres[i].EventValue)
          }
        }
        if (pres[i].EventType === "Same Month Feature Remove MRC" || pres[i].EventType === "Same Month Feature Deactivation MRC") {
          if (pres[i].RatePlan === "Phone Protection Plan") {
            pres[i].EventValue = -27
          } else {
            pres[i].EventValue = - parseFloat(pres[i].EventValue)
          }
        }
        if (pres[i].EventType === "Hardware Upgrade") {
          pres[i].EventValue = 75;
        }
        if (pres[i].EventType === "Same Month Postpaid Deactivation MRC") {
          if (typeof (pres[i].EventValue) === 'string') {
            pres[i].EventValue = -parseInt(pres[i].EventValue) * 3
          }
        }
        if (pres[i].EventType === "Hardware Upgrade Swap Clawback Amount" || pres[i].EventType === "Hardware Upgrade Return Clawback Amount") {
          if (typeof (pres[i].EventValue) === 'string') {
            pres[i].EventValue = -parseInt(pres[i].EventValue)
          }
        }
        if (pres[i].EventType === "TAB Handset Upgrade Clawback Amount"
          || pres[i].EventType === "TAB Handset Return Clawback Amount"
          || pres[i].EventType === "TAB Handset Downgrade Clawback Amount"
          || pres[i].EventType === "TAB Handset Sidegrade Clawback Amount"
        ) {
          if (typeof (pres[i].EventValue) === 'string') {
            pres[i].EventValue = -parseInt(pres[i].EventValue)
          }
        }
        if (pres[i].EventType === "TAB Handset Sidegrade"
          || pres[i].EventType === "TAB Handset Upgrade"
          || pres[i].EventType === "TAB Handset"
          || pres[i].EventType === "TAB Handset Add"
          || pres[i].EventType === "TAB Handset Downgrade"
        ) {
          pres[i].EventValue = parseFloat(pres[i].EventValue)
        }
        if (pres[i].EventType === "Deposit Collection") {
          pres[i].EventValue = parseFloat(pres[i].EventValue)
        }

        files.push(pres[i])
      }

    }

    const grouped = Object.values(files.reduce((acc, obj) => {
      // Group objects based on PhoneNumber
      acc[obj.PhoneNumber] = acc[obj.PhoneNumber] || [];
      acc[obj.PhoneNumber].push(obj);
      return acc;
    }, {}));

    let list = [];
    grouped.forEach((obj) => {

      let resultArr = obj.reduce((acc, curr) => {
        // Update planCommission and planCommType
        if (curr.EventType === "Post Paid Activation MRC" || curr.EventType === "Pre Paid Activation MRC" || curr.EventType === "Hardware Upgrade") {
          acc.planCommission = (acc.planCommission || 0) + curr.EventValue;
          acc.planCommType = (acc.planCommType ? acc.planCommType + " and " : "") + curr.EventType;
        }
        // Update addonCommission
        else if (curr.EventType === "Feature Activation MRC" || curr.EventType === "Feature Add MRC") {
          acc.addonCommission = (acc.addonCommission || 0) + curr.EventValue;
        }
        // Update tab
        else if (curr.EventType === "TAB Handset Sidegrade"
          || curr.EventType === "TAB Handset Upgrade"
          || curr.EventType === "TAB Handset"
          || curr.EventType === "TAB Handset Add"
          || curr.EventType === "TAB Handset Downgrade"
        ) {
          acc.tab = (acc.tab || 0) + curr.EventValue;
        } else if (curr.EventType === "Same Month Feature Remove MRC" || curr.EventType === "Same Month Feature Deactivation MRC") {
          acc.addonCommissionRemove = curr.EventValue;
        } else if (curr.EventType === "Same Month Postpaid Deactivation MRC") {
          acc.planCommissionDeactivation = curr.EventValue;
        } else if (curr.EventType === "Hardware Upgrade Swap Clawback Amount" || curr.EventType === "Hardware Upgrade Return Clawback Amount") {
          acc.hupClawbackAmount = curr.EventValue;
        } else if (curr.EventType === "TAB Handset Upgrade Clawback Amount"
          || curr.EventType === "TAB Handset Return Clawback Amount"
          || curr.EventType === "TAB Handset Downgrade Clawback Amount"
          || curr.EventType === "TAB Handset Sidegrade Clawback Amount"
        ) {
          acc.tabClawback = curr.EventValue;
        } else if (curr.EventType === "Deposit Collection") {
          acc.depositCollection = curr.EventValue;
        }
        return acc;
      }, {});
      // Add shared properties
      resultArr.Location = obj[0]?.Location;
      resultArr.TransactionDate = obj[0]?.TransactionDate;
      resultArr.PhoneNumber = obj[0]?.PhoneNumber;
      resultArr.customerName = obj[0]?.CustomerName;

      const values = Object.values(resultArr).filter(val => typeof val === 'number');
      const sum = values.reduce((acc, curr) => acc + curr, 0);
      resultArr.commission = sum;
      list.push(resultArr);
    });


    const matchNumberList1 = []
    const matchNumberList2 = []
    const notUploadedList = [];
    const notPresentinScoreList = [];
    const mismatch = [];
    cisArray.forEach(cis => {
      let exist = list.find(score => ((score.PhoneNumber === cis.phonenumber && score.Location === cis.cisstoreId) || (score.PhoneNumber === cis.tempNum && score.Location === cis.cisstoreId)))
      if (exist) {
        matchNumberList1.push(cis);
      } else {
        notPresentinScoreList.push(cis);
      }
    })

    list.forEach(score => {
      let exist = matchNumberList1.find(cis => (cis.phonenumber === score.PhoneNumber && score.Location === cis.cisstoreId) || (cis.tempNum === score.PhoneNumber && score.Location === cis.cisstoreId))
      if (exist && !score.planCommissionDeactivation && !score.addonCommissionRemove) {
        const { phonenumber, planCommission, addonCommission, deposit, retailHandset, cisstoreId, date, name, type, storeInvoice } = exist
        if (planCommission || addonCommission || deposit || retailHandset) {
          const customerComm = {
            phonenumber: phonenumber,
            commission: ((planCommission || 0) + (addonCommission || 0) + (deposit || 0) + (retailHandset || 0)),
            planCommission: planCommission,
            addonCommission: addonCommission,
            deposit: deposit,
            retailHandset: retailHandset,
            store: cisstoreId,
            date: date,
            name: name,
            type: type,
            storeInvoice: storeInvoice
          }
          if (customerComm.commission !== score.commission) {
            let data = {
              store: customerComm.store,
              uploadedActType: customerComm.type,
              scoreActType: score.planCommType,
              customerPhonenumber: customerComm.phonenumber,
              customerName: customerComm.name,
              scoreCustomerName: score.customerName,
              date: customerComm.date,
              storeInvoice: customerComm.storeInvoice,
              uploadedPlanComm: customerComm.planCommission,
              uploadedTab: customerComm.retailHandset,
              uploadedAddonComm: customerComm.addonCommission,
              scorePlanComm: score.planCommission,
              scoreAddonComm: score.addonCommission,
              scoreTab: score.tab
            }
            if (customerComm.planCommission < score.planCommission) {
              data.reason = 'We got extra commission for a plan'
            } else if (customerComm.planCommission > score.planCommission) {
              data.reason = 'We got less or no commission for a plan'
            }
            if (customerComm.addonCommission > (score.addonCommission !== undefined ? score.addonCommission : 0)) {
              data.reason = (data.reason || '') + 'We got less or no commission for an Addon'
            } else if (customerComm.addonCommission < (score.addonCommission !== undefined ? score.addonCommission : 0)) {
              data.reason = (data.reason || '') + 'We got extra commission for an Addon'
            }
            if (customerComm.retailHandset > score.tab) {
              data.reason = (data.reason || '') + 'We got less or no commission for Tab'
            } else if (customerComm.retailHandset < score.tab) {
              data.reason = (data.reason || '') + 'We got extra commission for Tab'
            }
            mismatch.push(data)
          }
          matchNumberList2.push(customerComm)
        }
      } else {
        notUploadedList.push(score)
      }
    }) */




    let result = []
    let disputes = []
    cisArray.forEach(customer => {
      let files = [];

      for (let i = 0; i < pres.length; i++) {
        if ((pres[i].PhoneNumber === customer.phonenumber || pres[i].PhoneNumber === customer.tempNum)
        ) {
          if (pres[i].EventType !== "Postpaid Activation MRC 2 yr Contract"
            && pres[i].EventType !== "TAB Boost Fee Amount"
            && pres[i].EventType !== "Post Paid Activation"
            && pres[i].EventType !== "Postpaid Activation 2 yr Contract"
            && pres[i].EventType !== "Postpaid Activation 2 yr Contract"
            && pres[i].EventType !== "TAB Boost Fee Swap Clawback Amount"
            && pres[i].EventType !== "TAB Boost Fee Return Clawback Amount"
            && pres[i].EventType !== "Same Month Postpaid Deactivation MRC 2 yr Contract"
            && pres[i].EventType !== "Gross Prepaid Activation"
            && pres[i].EventType !== "Pre Paid Activation"
            && pres[i].EventType !== "Pre Paid Activation 2nd Top-up"
            && pres[i].EventType !== "TAB Boost Fee"
            && pres[i].EventType !== "Same Month Deactivation"
            && pres[i].EventType !== "Same Month Postpaid Deactivation"
            && pres[i].EventType !== "Same Month Feature Remove"
            && pres[i].EventType !== "Duplicate Adjustment Gross Prepaid Activation"
            && pres[i].EventType !== "Prepaid Activation - No TopUp"
            && pres[i].EventType !== "Gross Prepaid Activation - Buyer Remorse Adjustment"
            && pres[i].EventType !== "TAB Handset Upgrade Clawback"
            && pres[i].EventType !== "Hardware Upgrade Swap Clawback"
            && pres[i].EventType !== "Feature Activation Included"
            && pres[i].EventType !== "Feature Clawback"
            && pres[i].EventType !== "Prepaid Feature Activation Included"
            && pres[i].EventType !== "Feature Add"
            && pres[i].EventType !== "Feature Activation"
            && pres[i].EventType !== "Gross Same Month Prepaid Feature Deactivation"
            && pres[i].EventType !== "TAB Boost Fee Swap Clawback"
            && pres[i].EventType !== "TAB Handset Return Clawback"
            && pres[i].EventType !== "TAB Boost Fee Return Clawback"
            && pres[i].EventType !== "TAB Handset Downgrade Clawback"
            && pres[i].EventType !== "Hardware Upgrade Return Clawback"
            && pres[i].EventType !== "Same Month Feature Deactivation"
            && pres[i].EventType !== "TAB Handset Sidegrade Clawback"
            && pres[i].EventType !== "PAP"
            && pres[i].EventType !== "PAP Clawback"
            && pres[i].EventType !== "PAP Clawback Amount"
            && pres[i].EventType !== "Gross Prepaid Feature Activation"
            && pres[i].EventType !== "Prepaid Feature Activation"
            && pres[i].EventType !== "Activation Clawback - PostPaid Suspend"
            && pres[i].EventType !== "PostPaid Clawback - Suspend"
            && pres[i].EventType !== "Activation Clawback MRC - PostPaid Suspend"
            && pres[i].EventType !== "Pre Paid Activation MRC 3"
            && pres[i].EventType !== "Pre Paid Activation MRC 2"
            && pres[i].EventType !== "Pre Paid Activation 2"
            && pres[i].EventType !== "Feature MRC Clawback"
            && pres[i].EventType !== "Feature Deactivation"
            && pres[i].EventType !== "Pre Paid Activation - No 2nd Top-up"
            && pres[i].EventType !== "Activation Clawback MRC - PostPaid"
            && pres[i].EventType !== "Activation Clawback - PostPaid"
            && pres[i].EventType !== "PostPaid Clawback - Deactivation"
            && pres[i].EventType !== "Postpaid Activation 2 yr Contract Suspend MRC"
            && pres[i].EventType !== "Postpaid Activation 2 yr Contract Suspend"
          ) {
            if (pres[i].EventType === "Post Paid Activation MRC") {
              pres[i].EventValue = parseFloat(pres[i].EventValue) * 3
            }
            if (pres[i].EventType === "Pre Paid Activation MRC") {
              if (pres[i].RatePlan === "By The Year 4G Nationwide Talk") {
                pres[i].EventValue = 25
              } else if (pres[i].RatePlan === "By The Year 4G Nationwide 15GB") {
                pres[i].EventValue = 30
              } else if (pres[i].RatePlan === "By The Year Nationwide 30GB") {
                pres[i].EventValue = 37
              } else if (pres[i].RatePlan === "By The Year 4G Nationwide Talk Text") {
                pres[i].EventValue = 32
              } else {
                pres[i].EventValue = parseFloat(pres[i].EventValue) * 1.34
              }
            }
            if (pres[i].EventType === "Feature Activation MRC" || pres[i].EventType === "Feature Add MRC" || pres[i].EventType === "Prepaid Feature Activation MRC") {
              if (pres[i].RatePlan === "Phone Protection Plan") {
                pres[i].EventValue = 27

              } else {
                pres[i].EventValue = parseFloat(pres[i].EventValue)
              }
            }
            if (pres[i].EventType === "Same Month Feature Remove MRC" || pres[i].EventType === "Same Month Feature Deactivation MRC") {
              if (pres[i].RatePlan === "Phone Protection Plan") {
                pres[i].EventValue = -27
              } else {
                pres[i].EventValue = - parseFloat(pres[i].EventValue)
              }
            }
            if (pres[i].EventType === "Hardware Upgrade") {
              pres[i].EventValue = 75;
            }
            if (pres[i].EventType === "Same Month Postpaid Deactivation MRC") {
              if (typeof (pres[i].EventValue) === 'string') {
                pres[i].EventValue = -parseInt(pres[i].EventValue) * 3
              }
            }
            if (pres[i].EventType === "Hardware Upgrade Swap Clawback Amount" || pres[i].EventType === "Hardware Upgrade Return Clawback Amount") {
              if (typeof (pres[i].EventValue) === 'string') {
                pres[i].EventValue = -parseInt(pres[i].EventValue)
              }
            }
            if (pres[i].EventType === "TAB Handset Upgrade Clawback Amount"
              || pres[i].EventType === "TAB Handset Return Clawback Amount"
              || pres[i].EventType === "TAB Handset Downgrade Clawback Amount"
              || pres[i].EventType === "TAB Handset Sidegrade Clawback Amount"
            ) {
              if (typeof (pres[i].EventValue) === 'string') {
                pres[i].EventValue = -parseInt(pres[i].EventValue)
              }
            }
            if (pres[i].EventType === "TAB Handset Sidegrade"
              || pres[i].EventType === "TAB Handset Upgrade"
              || pres[i].EventType === "TAB Handset"
              || pres[i].EventType === "TAB Handset Add"
              || pres[i].EventType === "TAB Handset Downgrade"
            ) {
              pres[i].EventValue = parseFloat(pres[i].EventValue)
            }
            if (pres[i].EventType === "Deposit Collection") {
              pres[i].EventValue = parseFloat(pres[i].EventValue)
            }

            files.push(pres[i])
          }
        }
      }

      files = files.filter(obj => obj.Location === customer.cisstoreId);
      let resultArr = files.reduce((acc, curr) => {
        // Update planCommission and planCommType
        if (curr.EventType === "Post Paid Activation MRC" || curr.EventType === "Pre Paid Activation MRC" || curr.EventType === "Hardware Upgrade") {
          acc.planCommission = (acc.planCommission || 0) + curr.EventValue;
          acc.planCommType = (acc.planCommType ? acc.planCommType + " and " : "") + curr.EventType;
        }
        // Update addonCommission
        else if (curr.EventType === "Feature Activation MRC" || curr.EventType === "Feature Add MRC") {
          acc.addonCommission = (acc.addonCommission || 0) + curr.EventValue;
        }
        // Update tab
        else if (curr.EventType === "TAB Handset Sidegrade"
          || curr.EventType === "TAB Handset Upgrade"
          || curr.EventType === "TAB Handset"
          || curr.EventType === "TAB Handset Add"
          || curr.EventType === "TAB Handset Downgrade"
        ) {
          acc.tab = (acc.tab || 0) + curr.EventValue;
        } else if (curr.EventType === "Same Month Feature Remove MRC" || curr.EventType === "Same Month Feature Deactivation MRC") {
          acc.addonCommissionRemove = curr.EventValue;
        } else if (curr.EventType === "Same Month Postpaid Deactivation MRC") {
          acc.planCommissionDeactivation = curr.EventValue;
        } else if (curr.EventType === "Hardware Upgrade Swap Clawback Amount" || curr.EventType === "Hardware Upgrade Return Clawback Amount") {
          acc.hupClawbackAmount = curr.EventValue;
        } else if (curr.EventType === "TAB Handset Upgrade Clawback Amount"
          || curr.EventType === "TAB Handset Return Clawback Amount"
          || curr.EventType === "TAB Handset Downgrade Clawback Amount"
          || curr.EventType === "TAB Handset Sidegrade Clawback Amount"
        ) {
          acc.tabClawback = curr.EventValue;
        } else if (curr.EventType === "Deposit Collection") {
          acc.depositCollection = curr.EventValue;
        }
        return acc;
      }, {});
      // Add shared properties
      resultArr.Location = files[0]?.Location;
      resultArr.TransactionDate = files[0]?.TransactionDate;
      resultArr.PhoneNumber = files[0]?.PhoneNumber;

      const values = Object.values(resultArr).filter(val => typeof val === 'number');
      const sum = values.reduce((acc, curr) => acc + curr, 0);
      resultArr.commission = sum;


      const phoneNumberMap = {};
      files.forEach(item => {
        const { PhoneNumber, EventValue, Location, EventType } = item;
        if (PhoneNumber in phoneNumberMap) {
          phoneNumberMap[PhoneNumber].commission += EventValue;
          phoneNumberMap[PhoneNumber].reason += EventValue < 0 ? EventType : ""
        } else {
          phoneNumberMap[PhoneNumber] = {
            phoneNumber: PhoneNumber,
            commission: EventValue,
            store: Location,
            reason: EventValue < 0 ? EventType : ""
          };
        }
      });
      delete phoneNumberMap.undefined;
      let result1 = Object.values(phoneNumberMap);

      const { phonenumber, planCommission, addonCommission, deposit, retailHandset, cisstoreId, date, name, type, storeInvoice } = customer
      const customerComm = {
        phonenumber: phonenumber,
        commission: (planCommission + addonCommission + deposit + retailHandset),
        planCommission: planCommission,
        addonCommission: addonCommission,
        deposit: deposit,
        retailHandset: retailHandset,
        store: cisstoreId,
        date: date,
        name: name,
        type: type,
        storeInvoice: storeInvoice
      }

      const singleObject = result1.length > 0 ? result1[0] : {};

      if (resultArr.commission < customerComm.commission && singleObject.reason === '') {

        disputes.push(resultArr, customerComm);
        result.push(customerComm)
      }
    })

    const resultArray = filterNonMatchingNumbers(cisdata, result);
    const storeLevelCommissionPerCis = {}
    const storeLevelCommissionPerCisForPrevMonth = []
    const storeList = []
    resultArray.forEach(store => {
      if (!storeList.includes(store.storeInvoice)) {
        storeList.push(store.storeInvoice)
      }
    })

    storeList.forEach(store => {
      let cisPerStore = resultArray.filter(item => item.storeInvoice === store)
      let prevCisStore = prevMonthCIS.month1.filter(item => item.storeInvoice === store)

      let computedCustomerList = cisCommisson(cisPerStore);
      storeLevelCommissionPerCis[store] = storeCommissionFromCis(computedCustomerList)
      let computedCustomerListPrev = cisCommisson(prevCisStore)
      let computedCustomerListPrevNew = []
      computedCustomerListPrev.forEach(item => {
        let exist = totalClawbacks.find(item2 => item2.phonenumber === item.phonenumber)
        if (exist) {
          computedCustomerListPrevNew.push(item)
        }
      })

      storeLevelCommissionPerCisForPrevMonth[store] = storeCommissionFromCis(computedCustomerListPrevNew)

    })

    setMisMatchData(disputes)
    let result1 = {};

    // Iterate over keys in array1
    for (let key in storeLevelCommissionPerCis) {
      // Initialize object for current key
      result1[key] = {};

      // Iterate over keys in array1[key]
      for (let subKey in storeLevelCommissionPerCis[key]) {
        // Subtract corresponding values from array2
        result1[key][subKey] = storeLevelCommissionPerCis[key][subKey] - storeLevelCommissionPerCisForPrevMonth[key][subKey];
      }
    }

    return result1
  }


  function CompileData() {

    const [resultFinal, notUploaded] = ScoreCardCompile(cisdata, totalScorecard);
    setFinalCommissionDownload(resultFinal)
    setNotUploadedFile(notUploaded)

    const resultScoreMM = mismatchScoreData(cisArray, pres)
    const dateString = currentMandY.join(' ');
    const dataObject = {
      monthYear: dateString,
      employeesCommission: '',
      storesCommission: resultScoreMM
    };
    console.log(dataObject)
    let FilterPromise = filteredCommissionAfterScorecard({ dataObject })
    toast.promise(FilterPromise, {
      loading: 'Uploading...',
      success: <b>Uploaded Successfully...!</b>,
      error: <b>Not uploaded</b>

    });


    /* ------------------------------------------------------------------------------------------------ */



  }
  const DateForTarget = (target) => {
    setTarget(target)
  }
  const manualAcceandAppleCare = (data) => {

  }
  const currentMonthYear = (data) => {
    setCurrentMandY(data);
  }
  const setLoadingCondition = (data) => {

  }
  const accessoryListOA = (data) => {

  }
  const OAMSRP = (data) => {

  }
  const accessoryListAR = (data) => {

  }
  const AAMSRP = (data) => {

  }


  const onTypeChange = async (e) => {
    e.preventDefault();
    await totalCommission();
  }

  return (
    <div className="scorecard">
      <AdminSidebar />
      <div className="scorecard-container">
        <div className="navbarcontainer">
          <Navbar />
        </div>
        <div className="scorecard-upload">
          <div className="scorecard-upload1">
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className='top'>
              <div className="refund-section">
                <label>Refund Data Filter</label>
                <RefundFilter onDataLoaded={refundCisData} />
                {refundData?.length > 0 && <span className="cis-available">Refund data available</span>}
              </div>
              <div className="cis-section">
                <label>CIS Data Filter</label>
                <DateFilter AAMSRP={AAMSRP} accessoryListAR={accessoryListAR} OAMSRP={OAMSRP} setLoadingCondition={setLoadingCondition} accessoryListOA={accessoryListOA} onDataLoaded={updateTableData} targetData={DateForTarget} manualData={manualAcceandAppleCare} currentMonth={currentMonthYear} />
                {cisdata?.length > 0 && <span className="cis-available">CIS data available</span>}
              </div>

              <div className='block'>
                <div className="top-block">
                  <label >Upload Current Scorecard:</label>
                </div>
                <div className='bottom-block'>
                  <input onChange={readactivationFile}
                    className="file-input" /* ref={cisRef} *//*  accept='.pdf' */
                    multiple={false} type="file" id='cis' name='cis' />
                  <label htmlFor="cis" className="file-label">Choose a file</label>
                </div>
                <div className='bottom-button'>
                  {pres?.length > 0 && <label>Scorecard data available</label>}
                  <button onClick={onTypeChange} className="upload-button">Upload</button>
                  {showComplieBtn && <button onClick={CompileData} className="upload-button">Complile Data</button>}
                </div>
              </div>


              <div className="mismatchReport">
                <h3>Download the Mismatch Scorecard Report</h3>
                <CSVLink data={misMatchData ? misMatchData : ''} className='edit-btn'>Download</CSVLink>
              </div>
              <div className="mismatchReport">
                <h3>Download Employee Commission File</h3>
                <button className='edit-btn' onClick={downloadExcel}>Download</button>
                {/* <CSVLink data={finalCommissionDownload ? finalCommissionDownload : ''} className='edit-btn'>Download</CSVLink> */}
              </div>
              <div className="mismatchReport">
                <h3>Download the NotUploaded File</h3>
                <CSVLink data={notUploadedFile ? notUploadedFile : ''} className='edit-btn'>Download</CSVLink>
              </div>

            </div>

            {/* <div className="bottom">

              <div className="table-bottom">
                <table>
                  <thead>
                    <tr>
                      <th>Transection Date</th>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th>Cis Plan Commission</th>
                      <th>Cis Addon Commission</th>
                      <th>Scorecard Plan Commission</th>
                      <th>Scorecard Addon Commission</th>
                      <th>CIS Tab</th>
                      <th>ScoreCard Tab</th>
                      <th>Type</th>

                    </tr>
                  </thead>
                  <tbody>
                    {misMatchData?.map((item, id) => (
                      <tr key={id}>
                        <td>{item.transectionDate}</td>
                        <td>{item.customerName}</td>
                        <td>{item.phoneNumber}</td>
                        <td style={{ color: item.scorePlanComm > item.cisPlanCommission ? 'red' : '' }}>{item.cisPlanCommission}</td>
                        <td style={{ color: item.scoreAddonComm > item.cisAddonCommission ? 'red' : '' }}>{item.cisAddonCommission}</td>
                        <td style={{ color: item.scorePlanComm < item.cisPlanCommission ? 'red' : '' }}>{item.scorePlanComm}</td>
                        <td style={{ color: item.scoreAddonComm < item.cisAddonCommission ? 'red' : '' }}>{item.scoreAddonComm}</td>
                        <td style={{ color: item.cisTab < item.scoreTab ? 'red' : '' }}>{item.cisTab}</td>
                        <td style={{ color: item.cisTab > item.scoreTab ? 'red' : '' }}>{item.scoreTab}</td>
                        <td>{item.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div> */}
            {/* <div className="bottom">
              <h3>Unmatched data</h3>
              <div className="table-bottom">
                <table>
                  <thead>
                    <tr>
                      <th>Transection Date</th>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th>Cis Plan Commission</th>
                      <th>Cis Addon Commission</th>
                      <th>Scorecard Plan Commission</th>
                      <th>Scorecard Addon Commission</th>
                      <th>CIS Tab</th>
                      <th>ScoreCard Tab</th>
                      <th>Type</th>

                    </tr>
                  </thead>
                  <tbody>
                    {unmatchedList?.map((item, id) => (
                      <tr key={id}>
                        <td>{item.transectionDate || "n/a"}</td>
                        <td>{item.customerName}</td>
                        <td>{item.phoneNumber}</td>
                        <td style={{ color: item.scorePlanComm > item.cisPlanCommission ? 'red' : '' }}>{item.cisPlanCommission}</td>
                        <td style={{ color: item.scoreAddonComm > item.cisAddonCommission ? 'red' : '' }}>{item.cisAddonCommission}</td>
                        <td style={{ color: item.scorePlanComm < item.cisPlanCommission ? 'red' : '' }}>{item.scorePlanComm || 0}</td>
                        <td style={{ color: item.scoreAddonComm < item.cisAddonCommission ? 'red' : '' }}>{item.scoreAddonComm}</td>
                        <td style={{ color: item.cisTab < item.scoreTab ? 'red' : '' }}>{item.cisTab}</td>
                        <td style={{ color: item.cisTab > item.scoreTab ? 'red' : '' }}>{item.scoreTab || 0}</td>
                        <td>{item.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div> */}
          </div>

        </div>
      </div>
    </div>
  )
}

export default ScoreCard