import React, { useState, useEffect } from 'react'
import './EmployeeCommissionReport.scss';
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import Commissions from '../../components/Commissions';
import toast, { Toaster } from 'react-hot-toast';
import DateFilter from '../../components/DateFilter';
import { useAuthStore } from '../../store/store';
import { getEmployeeCommissionReport, getUser } from '../../helper/helper';

const EmployeeCommissionReport = () => {
  const { username } = useAuthStore(state => state.auth);
  const [employeeData, setEmployeeData] = useState([]);
  const [cisdata, setCisdata] = useState([]);
  const [target, setTarget] = useState([]);
  const [manualData, setManualData] = useState([]);
  const [targetRow, setTargetRow] = useState([]);
  const [/* manualDataList, */ setManualDataList] = useState({
    accessories: 0,
    applecare: 0,
  });
  const [filteredECD, setFilteredECD] = useState([])
  const [filteredECDNumbers, setFilteredECDNumbers] = useState([])
  const [roleUser, setRoleUser] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const [apiDataECR, setApiDataECR] = useState([])
  const [userApiData, setUserApiData] = useState([]);
  const [stores, setStores] = useState([]);
  const [Employees, setEmployees] = useState([]);
  let filterStores = []
  let filterEmployee = []
  let FCRdata = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, addonsCount: 0 }

  useEffect(() => {
    const dataRole = getUser({ username: username });
    dataRole.then((data) => {
      setRoleUser(data.data.role)
      setUserApiData(data.data)
    })
  }, [username])

  useEffect(() => {
    const data1 = getEmployeeCommissionReport(currentDate);
    
    data1.then((data2) => {
      setApiDataECR(data2?.data[0]?.employeesCommission);
    })
  }, [currentDate])

  const updateTableData = (data) => {
    let finalArray = []
    if (roleUser === 'manager') {
      data?.forEach(val => {
        let isExist = userApiData.stores.split(',').some(item => item === val.storeInvoice)
        if (isExist) finalArray.push(val)
      })
      setCisdata(finalArray)
      setEmployeeData(finalArray)
      let storesList = finalArray.map(store => store.storeInvoice);
      storesList?.forEach(val => {
        let isExist = filterStores.some(store => store === val);
        if (!isExist) filterStores.push(val);
      })
      let employeeList = finalArray.map(emp => emp.user)
      employeeList?.forEach(val => {
        let isExist = filterEmployee.some(employee => employee === val);
        if (!isExist) filterEmployee.push(val);
      })
      setStores(filterStores);
      setEmployees(filterEmployee)
      const testData = []
      filterStores?.forEach(val => {
        apiDataECR.map(store => {
          if (store.storeInvoice === val) {
            testData.push(store);
          }
          return store;
        });
      })
      testData.map(item => {
        FCRdata.postCount += item.post
        FCRdata.preCount += item.pre
        FCRdata.hupCount += item.hup
        FCRdata.autopayCount += item.ap
        FCRdata.addonsCount += item.addon
        return FCRdata
      })
      setFilteredECDNumbers(FCRdata);
      setFilteredECD(testData);

    } else {
      
      data?.forEach(val => {
        if (val.user === username) finalArray.push(val)
      })
      setCisdata(finalArray);
      setEmployeeData(finalArray);
      let storesList = finalArray.map(store => store.storeInvoice);
      storesList?.forEach(val => {
        let isExist = filterStores.some(store => store === val);
        if (!isExist) filterStores.push(val);
      })
      setStores(filterStores);
      const employeeNumbers = []
      apiDataECR?.forEach(item => {
        if (item.employee === username) {
          employeeNumbers.push(item)
        }
      })
      employeeNumbers.map(item => {
        FCRdata.postCount += item.post
        FCRdata.preCount += item.pre
        FCRdata.hupCount += item.hup
        FCRdata.autopayCount += item.ap
        FCRdata.addonsCount += item.addon
        return FCRdata
      })
      setFilteredECDNumbers(FCRdata);
      setFilteredECD(employeeNumbers);
    }
  };

  const DateForTarget = (target) => {
    let finalArray = []
    if (roleUser === 'manager') {
      filterStores?.forEach(val => {
        target.map(store => {
          if (store.storeinvoice === val) {
            finalArray.push(store)
          }
          return store
        });
      })
      const results = calculateTarget(finalArray);
      setTargetRow(results)
      setTarget(finalArray)
    } else {
      target?.forEach(val => {
        if (val.username === username) finalArray.push(val)
      })
      const results = calculateTarget(finalArray);
      setTargetRow(results)
      setTarget(finalArray)
    }
  }

  const manualAcceandAppleCare = (data) => {
    let finalArray = []
    if (roleUser === 'manager') {
      filterStores?.forEach(val => {
        data?.forEach(store => {
          if (store.storeid === val) finalArray.push(store)
        })
      })
      setManualData(finalArray);
    } else {
      data?.forEach(val => {
        if (val.employee === username) finalArray.push(val)
      })
      setManualData(finalArray);
    }
  }

  const currentMonthYear = (dataDate) => {
    setCurrentDate(dataDate[0] + ' ' + dataDate[1])
  }

  const storeNameList = {
    OSHWTIN: "Lake Vista Plaza",
    LAKESIN: "Lakeshore & Islington",
    VYAD2IN: "Center Mall Barton",
    TCPNFIN: "Town Center Plaza",
    VYACBIN: "Cedarbrae Mall",
    BARRIIN: "Barrie",
    VYAREIN: "Rexdale & Islington",
    DTWICIN: "Briarfield Shopping Centre",
    DAN01IN: "Danforth & Woodbine",
    VYOSHIN: "Big Bay",
    VYAMLIN: "Markham & Lawrence",
    VYAWLIN: "Weston & Lawrence",
    DUNBLIN: "Oriental Centre"
  }

  function calculateTarget(target) {
    const targetData = [];
    target.map(item => {
      if (targetData.hasOwnProperty("postpaidtarget")) {
        targetData.postpaidtarget += parseInt(item.postpaidtarget);
        targetData.prepaidtarget += parseInt(item.prepaidtarget);
        targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
        targetData.addon += parseInt(item.addon);
        targetData.accessories += parseInt(item.accessories);
        targetData.applecare += parseInt(item.applecare);
        targetData.smssurvey += parseInt(item.smssurvey);
      } else {
        targetData.postpaidtarget = parseInt(item.postpaidtarget);
        targetData.prepaidtarget = parseInt(item.prepaidtarget);
        targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
        targetData.addon = parseInt(item.addon);
        targetData.accessories = parseInt(item.accessories);
        targetData.applecare = parseInt(item.applecare);
        targetData.smssurvey = parseInt(item.smssurvey);
      }
      return targetData;
    })
    return targetData;
  }

  /*  function updateApi(ECRList, role) {
 
 
     if (role === 'manager') {
 
       const testData = []
       filterStores?.forEach(val => {
         ECRList.map(store => {
           if (store.storeInvoice === val) {
             testData.push(store);
           }
           return store;
         });
       })
       console.log(filterStores, ECRList, testData);
 
       testData.map(item => {
         FCRdata.postCount += item.post
         FCRdata.preCount += item.pre
         FCRdata.hupCount += item.hup
         FCRdata.autopayCount += item.ap
         FCRdata.addonsCount += item.addon
         return FCRdata
       })
       setFilteredECDNumbers(FCRdata);
       setFilteredECD(testData);
 
     } else {
       const employeeNumbers = []
       ECRList?.forEach(item => {
         if (item.employee === username) {
           employeeNumbers.push(item)
         }
       })
       employeeNumbers.map(item => {
         FCRdata.postCount += item.post
         FCRdata.preCount += item.pre
         FCRdata.hupCount += item.hup
         FCRdata.autopayCount += item.ap
         FCRdata.addonsCount += item.addon
         return FCRdata
       })
       setFilteredECDNumbers(FCRdata);
       setFilteredECD(employeeNumbers);
     }
 
   } */

  const handleStoreChange = (event) => {
    const { value } = event.target;
    if (value !== '') {
      const data = cisdata.filter(item => item.storeInvoice === value);
      const targetData = target?.filter(item => item.storeinvoice === value);
      const results = calculateTarget(targetData);
      const manualData1 = manualData?.filter(item => item.storeid === value);
      const manualResult = calculateManualData(manualData1)
      setManualDataList({
        accessories: manualResult.accessories,
        applecare: manualResult.applecare
      })
      filteredECD.map(item => {
        if (item.storeInvoice === value) {

          FCRdata.postCount += item.post
          FCRdata.preCount += item.pre
          FCRdata.hupCount += item.hup
          FCRdata.autopayCount += item.ap
          FCRdata.addonsCount += item.addon
          return FCRdata
        }
        setFilteredECDNumbers(FCRdata)
        return FCRdata
      })
      setTargetRow(results)
      setEmployeeData(data);
      if (results.hasOwnProperty("postpaidtarget") === false) {
        toast.error(
          `You don't have Targets set for ${storeNameList[value.trim()]}`
        )
      }
    } else {
      filteredECD.map(item => {
        FCRdata.postCount += item.post
        FCRdata.preCount += item.pre
        FCRdata.hupCount += item.hup
        FCRdata.autopayCount += item.ap
        FCRdata.addonsCount += item.addon
        return FCRdata
      })
      setFilteredECDNumbers(FCRdata)
      const manualResult = calculateManualData(manualData)
      setManualDataList({
        accessories: manualResult.accessories,
        applecare: manualResult.applecare
      })
      const results = calculateTarget(target);
      setTargetRow(results)
      setEmployeeData(cisdata);
    }
  };

  const handleEmployeeChange = (event) => {
    const { value } = event.target;
    if (value !== '') {
      const data = cisdata.filter(item => item.user === value);
      const testData = target.filter(item => item.username === value);
      const results = calculateTarget(testData);
      const manualData1 = manualData?.filter(item => item.employee === value);
      const manualResult = calculateManualData(manualData1)
      setManualDataList({
        accessories: manualResult.accessories,
        applecare: manualResult.applecare
      })
      filteredECD.map(item => {
        if (item.employee === value) {

          FCRdata.postCount += item.post
          FCRdata.preCount += item.pre
          FCRdata.hupCount += item.hup
          FCRdata.autopayCount += item.ap
          FCRdata.addonsCount += item.addon
          return FCRdata
        }
        setFilteredECDNumbers(FCRdata)
        return FCRdata
      })
      setTargetRow(results)
      setEmployeeData(data);
    } else {
      filteredECD.map(item => {
        FCRdata.postCount += item.post
        FCRdata.preCount += item.pre
        FCRdata.hupCount += item.hup
        FCRdata.autopayCount += item.ap
        FCRdata.addonsCount += item.addon
        return FCRdata
      })
      setFilteredECDNumbers(FCRdata)
      const manualResult = calculateManualData(manualData)
      setManualDataList({
        accessories: manualResult.accessories,
        applecare: manualResult.applecare
      })
      const results = calculateTarget(target);
      setTargetRow(results)
      setEmployeeData(cisdata);
    }
  }

  function calculateManualData(item) {
    const manualRes = []
    item.map(item => {
      if (manualRes.hasOwnProperty('accessories')) {
        manualRes.accessories += parseInt(item.accessories);
        manualRes.applecare += parseInt(item.applecare);
      } else {
        manualRes.accessories = parseInt(item.accessories);
        manualRes.applecare = parseInt(item.applecare);
      }
      return manualRes
    })
    return manualRes
  }

  let data = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, withDeviceCount: 0, pppCount: 0, addonsCount: 0 }

  employeeData.map((item) => {

    if (item.phoneProtectionPlan === "Accepted") {
      data.pppCount++;
    }
    if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
      data.postCount++;
    }
    if (item.connectionType === "Prepaid") {
      data.preCount++;
    }
    if (item.simNumber === "N/A" && item.imei !== "N/A") {
      data.hupCount++;
    }
    if (item.autoPayPre === "YES" || item.autoPayPost === "YES" || item.autoPayPre === "successfully" || item.autoPayPre === "successfully for Pre - Authorized Payments" || item.autoPayPost === "successfully for Pre - Authorized Payments") {
      data.autopayCount++;
    }
    if (item.simNumber !== "N/A" && item.imei !== "N/A") {
      data.withDeviceCount++;
    }
    if (item.addons !== "") {
      data.addonsCount++;
    }
    return (data.pppCount, data.postCount, data.preCount, data.hupCount, data.autopayCount, data.withDeviceCount, data.addonsCount);
  })

  return (
    <div className="ecr">
      <Sidebar />
      <div className="ecrContainer">
        <div className='navContainer'>
          <Navbar />
        </div>
        <div className="ecrDash">
          <div className="ecrDash-container">
            <div className='filter-menu-list'>
              <Toaster position='top-center' reverseOrder={false}></Toaster>
              <div className='filter-menu'>
                <DateFilter onDataLoaded={updateTableData} targetData={DateForTarget} manualData={manualAcceandAppleCare} currentMonth={currentMonthYear} />
              </div>
              {cisdata.length > 0 && (
                <>
                  <div className='filter-menu'>
                    <label >Store:</label>
                    <select name="storeslist" id="storeslist" onChange={handleStoreChange}>
                      <option value="" > All Stores</option>
                      {stores.map(storeslist => (
                        <option value={storeslist} key={storeslist} >
                          {storeNameList[storeslist.trim()]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {roleUser === 'manager' && (
                    <div className='filter-menu'>
                      <label >Employee:</label>
                      <select name="employeelist" id="employeelist" onChange={handleEmployeeChange}>
                        <option value="">All Employees</option>
                        {Employees.map(storeslist => (
                          <option key={storeslist}>
                            {storeslist}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='bot-widgets'>
              <div className='widgetcontainer'>
                <h3>Commission(Uploaded)</h3>
                <Commissions cisdata={employeeData} targetRow={targetRow} data={data} />
              </div>
              <div className='widgetcontainer'>
                <h3>Commission(After Processing)</h3>
                <div className='widget'>
                  <div className="left">
                    <span className="title">Postpaid</span>
                    <span className="counter">${filteredECDNumbers.postCount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>

                  <div className="left">
                    <span className="title">Prepaid</span>
                    <span className="counter">${filteredECDNumbers.preCount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>

                  <div className="left">
                    <span className="title">HUP</span>
                    <span className="counter">${filteredECDNumbers.hupCount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>

                  <div className="left">
                    <span className="title">AUTOPAY</span>
                    <span className="counter">${filteredECDNumbers.autopayCount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>
                  <div className="left">
                    <span className="title">Addons</span>
                    <span className="counter">${filteredECDNumbers.addonsCount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>

                  <div className="left">
                    <span className="title">Total</span>
                    <span className="counter">${(filteredECDNumbers.postCount + filteredECDNumbers.preCount + filteredECDNumbers.hupCount + filteredECDNumbers.autopayCount + filteredECDNumbers.addonsCount)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeCommissionReport