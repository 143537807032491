import React from 'react'

const EditableRows = ({editFormData, handleEditFormChange, handleCancelClick}) => {
    return (
        <tr>
            <td><input
                type="text"
                name="employeename"
                required="required"
                placeholder="Employee Name"
                value={editFormData.employeename}
                onChange={handleEditFormChange}           
            /></td>
            <td><input
                type="text"
                name="storename"
                required="required"
                placeholder="Store Name"
                value={editFormData.storename}
                onChange={handleEditFormChange}           
            /></td>
            <td><input
                type="number"
                name="prepaidtarget"
                required="required"
                placeholder="Prepaid Target"
                value={editFormData.prepaidtarget}
                onChange={handleEditFormChange}           
            /></td>
            <td><input
                type="number"
                name="postpaidtarget"
                required="required"
                placeholder="Postpaid Target"
                value={editFormData.postpaidtarget}
                onChange={handleEditFormChange}           
            /></td>
            <td><input
                type="number"
                name="hardwareupgrade"
                required="required"
                placeholder="Hardware Upgrade"
                value={editFormData.hardwareupgrade}
                onChange={handleEditFormChange}            
            /></td>
            <td><input
                type="number"
                name="addon"
                required="required"
                placeholder="Add on"
                value={editFormData.addon}
                onChange={handleEditFormChange}            
            /></td>
            <td><input
                type="number"
                name="accessories"
                required="required"
                placeholder="Accessories"
                value={editFormData.accessories}
                onChange={handleEditFormChange}            
            /></td>
            <td><input
                type="number"
                name="applecare"
                required="required"
                placeholder="Apple Care"
                value={editFormData.applecare}
                onChange={handleEditFormChange}            
            /></td>
            <td><input
                type="number"
                name="smssurvey"
                required="required"
                placeholder="SMS Survey"
                value={editFormData.smssurvey}
                onChange={handleEditFormChange}            
            /></td>
            <td>
                <button className='edit-btn' type='submit'>Save</button>
                <button className='edit-btn' type='button' onClick={handleCancelClick}>Cancel</button>
            </td>
        </tr>
    )
}

export default EditableRows