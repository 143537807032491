import React, { useState, useEffect } from 'react'
import './UserList.scss'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import { getAllUsersData } from '../../helper/helper';
import ReadOnlyUserList from './ReadOnlyUserList'
/* import EditableUserList from '../../components/EditableUserList' */
import toast, { Toaster } from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import { MenuItem, Select } from '@mui/material';
const UserList = () => {

  const [userdata, setUserdata] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState('Active');
  /* const [editEmployeeId, setEditEmployeeId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    fullname: '',
    username: '',
    password: '',
    email: '',
    role: '',
    phonenumber: '',
    employeeid: '',
    stores: '',
    dob: '',
  }) */

  useEffect(() => {
    getRawUserData();

  }, [])
  async function getRawUserData() {
    try {
      let { data } = await getAllUsersData();
      setUserdata(data);
      const filtered = data.filter((user) => {
        const matchesStatus = user.employmentStatus === employmentStatus;
        return matchesStatus;
      });
      setFilteredUsers(filtered);
    } catch (error) {
      return error
    }
  }
  const fetchUserList = () => {
    getRawUserData();
  }
  /* const handleEditEmployee = (event, userdata) => {
    event.preventDefault();
    setIsModalOpen(true);
    setEditEmployeeId(userdata._id);
    const formValues = {
      fullname: userdata.fullname,
      username: userdata.username,
      password: userdata.password,
      email: userdata.email,
      role: userdata.role,
      phonenumber: userdata.phonenumber,
      employeeid: userdata.employeeid,
      stores: userdata.stores,
      dob: userdata.dob
    }
    setEditFormData(formValues)
  } */

  /* const handleDeleteClick = (UserId) => {
    const newUsers = [...userdata];
    const index = newUsers.findIndex(user => user._id === UserId);
    let user = newUsers.splice(index, 1)

    if (window.confirm(`Are you sure you want to delete ${user[0].fullname}'s data?`)) {
      const deleteUsersPromise = deleteUserData({ id: UserId });
      toast.promise(deleteUsersPromise, {
        loading: 'Deleting...',
        success: <b>Deleted Successfully...!</b>,
        error: <b>Could not Delete.</b>
      }).then(() => {
        getRawUserData();

      });
      setUserdata(newUsers)

    } else {
      window.alert("not done");
    }


  } */

  /* const handleCancelClick = () => {
    setEditEmployeeId(null);
  }

  const handleEditFormChange = (event) => {

    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    let fieldValue = '';
    if (fieldName === "stores") {
      let list = Array.from(event.target.selectedOptions, (option) => option.value)
      const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
      }
      const selectedKeys = Object.keys(storeNameList).filter(
        (key) => list.includes(storeNameList[key])
      );
      list = selectedKeys.join(',')
      fieldValue = list
    } else {
      fieldValue = event.target.value;
    }


    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData)
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    setIsModalOpen(false);
    const editUsers = {
      _id: editEmployeeId,
      fullname: editFormData.fullname,
      username: editFormData.username,
      password: editFormData.password,
      email: editFormData.email,
      role: editFormData.role,
      phonenumber: editFormData.phonenumber,
      employeeid: editFormData.employeeid,
      stores: editFormData.stores,
      dob: editFormData.dob
    }
    const newUsers = [...userdata]
    const index = userdata.findIndex((user) => user._id === editEmployeeId);
    const editRow = newUsers[index] = editUsers;

    const editRowPromise = editUserListData({
      _id: editRow._id,
      fullname: editRow.fullname,
      username: editRow.username,
      password: editRow.password,
      email: editRow.email,
      role: editRow.role,
      phonenumber: editRow.phonenumber,
      employeeid: editRow.employeeid,
      stores: editRow.stores,
      dob: editRow.dob
    })
    toast.promise(editRowPromise, {
      loading: 'Editing...',
      success: <b>Editied Successfully...!</b>,
      error: <b>Could not Edit.</b>
    }).then(() => {
      getRawUserData();

    })
    setUserdata(newUsers);
    setEditEmployeeId(null);
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  }; */

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    // Filter the users based on the search query
    const filtered = userdata.filter((user) =>
      user.fullname.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;

    setEmploymentStatus(status);
    const filtered = userdata.filter((user) => {
      const matchesStatus = user.employmentStatus === status;
      return matchesStatus;
    });
    setFilteredUsers(filtered);
    if (status === "All") {
      setFilteredUsers(userdata);
    }
  };

  /* const filterUsers = (userList, query, status) => {
    const filtered = userList.filter((user) => {
      const matchesQuery = user.fullNa.toLowerCase().includes(query.toLowerCase());
      const matchesStatus = user.EmploymentStatus === status;
      return matchesQuery && matchesStatus;
    });
    setFilteredUsers(filtered);
  }; */

  return (
    <div className='list'>
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <AdminSidebar />
      <div className="listContainer">
        <div className="navbarcontainer">
          <Navbar />
        </div>
        {/*  <div className="tablecontainer" >*/}


        <div className="bottomtable">
          <div className="filterContainer">
            <TextField
              label="Search Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchQuery}
              onChange={handleSearchChange}

            />
            <Select
              value={employmentStatus}
              onChange={handleStatusChange}
              displayEmpty
              variant="outlined"
              margin="normal"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </div>

          <div className='userlist-form' /* onSubmit={handleEditFormSubmit} */>
            <table className="table-auto">
              <thead>
                <tr>
                  <th >Name</th>
                  <th>Username</th>
                  {/* <th>Password</th> */}
                  <th>Email</th>
                  <th>Role</th>
                  <th>Phone No.</th>
                  <th>Emp/Id</th>
                  <th>Stores</th>
                  <th>DOB</th>
                  <th>Status</th>
                  <th className="actions" rowSpan="2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((user, index) => (
                  <ReadOnlyUserList
                    userdata={user}
                    key={index}
                    fetchUserList={fetchUserList}
                  /* handleEditEmployee={handleEditEmployee}
                  handleDeleteClick={handleDeleteClick} */
                  />
                ))}
              </tbody>
              {/* <tbody>
                  {userdata.map((userdata, index) => (
                    <Fragment key={index}>
                      {editEmployeeId === userdata._id ?
                        (<EditableUserList
                          editFormData={editFormData}
                          key={userdata}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick} />)
                        : (
                          <ReadOnlyUserList
                            userdata={userdata}
                            key={userdata}
                            handleEditEmployee={handleEditEmployee}
                            handleDeleteClick={handleDeleteClick}
                          />)}
                    </Fragment>
                  ))}
                </tbody> */}
            </table>
          </div>

          {/* {isModalOpen && (
            <EditUserModal
              editFormData={editFormData}
              handleClose={handleCloseModal}
              handleEditFormChange={setEditFormData}
              handleEditFormSubmit={handleEditFormSubmit}
            />
          )} */}
        </div>
        {/* </div>*/}
      </div>
    </div>
  )
}

export default UserList