import React, { useState } from 'react'
import { getFilterRefundByDate } from '../helper/helper'

const RefundFilter = ({ onDataLoaded }) => {
    const currentDate = new Date();

    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // Calculate the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Format the first and last day as strings (YYYY-MM-DD)
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);

   
    const handleSearch = () => {
        async function getRawCisData() {
            try {
                let { data } = await getFilterRefundByDate({ startDate, endDate });
                onDataLoaded(data);


            } catch (error) {
                return error
            }
        }
        getRawCisData();
    };
    return (
        <div className='filter-datemenu'>
            <label htmlFor="startDate">From:</label>
            <input
                type="date"
                id="startDate"
                className='filter-datemenu-input'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="endDate">To:</label>
            <input
                type="date"
                id="endDate"
                className='filter-datemenu-input'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
            />
            <button className='filter-datemenu-button' onClick={handleSearch}>Search</button>
        </div>
    )
}

export default RefundFilter