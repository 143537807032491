import React, { useState, useEffect } from 'react'
import { employeeCommissionCalculate, employeeCommissionCalculate2 } from './ReusableFunctions'

const Commissions = ({ cisdata, targetRow, data, totalTarget, currentFilter }) => {

    function calculateCommissions(cisdata, targetData) {
        const cutoffDate = new Date('2024-06-01');
        const firstEntryDate = new Date(cisdata[0]?.tenderedDate);
        const lastEntryDate = new Date(cisdata[cisdata.length - 1]?.tenderedDate);
        if (firstEntryDate < cutoffDate && lastEntryDate < cutoffDate) {

            return employeeCommissionCalculate(cisdata, targetData);
        } else if (firstEntryDate >= cutoffDate && lastEntryDate >= cutoffDate) {

            return employeeCommissionCalculate2(cisdata, targetData);
        }
    }

    /* console.log(cisdata, targetRow, data, totalTarget, currentFilter) */
    const [commissionFinal, setCommissionFinal] = useState({
        postpaidCommission: 0,
        prepaidCommission: 0,
        hupCommission: 0,
        addonCommission: 0,
        autopayCommission: 0,
    })

    useEffect(() => {
        const store = [];
        const reps = [];
        cisdata && cisdata?.forEach(item => {
            let exist = store.some(item1 => item1 === item.storeInvoice)
            if (!exist) store.push(item.storeInvoice);
            let exist2 = reps.some(item1 => item1 === item.user)
            if (!exist2) reps.push(item.user);
        })
        let post = 0
        let pre = 0
        let hup = 0
        let add = 0
        let ap = 0
        
        if (store.length < reps.length && currentFilter !== undefined) {
            reps.forEach(item => {
                const cisdatafinal = cisdata?.filter(item1 => item1.user === item)
                const targetFinal = totalTarget?.filter(item1 => item1.username === item)
                const targetData = [];
                targetFinal?.map(item => {


                    if (targetData?.hasOwnProperty("postpaidtarget")) {
                        targetData.postpaidtarget += parseInt(item.postpaidtarget);
                        targetData.prepaidtarget += parseInt(item.prepaidtarget);
                        targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
                        targetData.addon += parseInt(item.addon);
                        targetData.accessories += parseInt(item.accessories);
                        targetData.applecare += parseInt(item.applecare);
                        targetData.smssurvey += parseInt(item.smssurvey);
                    } else {
                        targetData.postpaidtarget = parseInt(item.postpaidtarget);
                        targetData.prepaidtarget = parseInt(item.prepaidtarget);
                        targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
                        targetData.addon = parseInt(item.addon);
                        targetData.accessories = parseInt(item.accessories);
                        targetData.applecare = parseInt(item.applecare);
                        targetData.smssurvey = parseInt(item.smssurvey);
                    }
                    return targetData;
                })


                const commissions = calculateCommissions(cisdatafinal, targetData);

                post += commissions.post
                pre += commissions.pre
                hup += commissions.hup
                add += commissions.addon
                ap += commissions.ap

                setCommissionFinal({
                    postpaidCommission: post,
                    prepaidCommission: pre,
                    hupCommission: hup,
                    addonCommission: add,
                    autopayCommission: ap,
                })
            });
    
        } else if (reps.length < store.length && currentFilter !== undefined) {

            store.forEach(item => {
                const cisdatafinal = cisdata?.filter(item1 => item1.storeInvoice === item);

                const targetFinal = totalTarget?.filter(item1 => item1.storeinvoice === item && item1.username === reps[0])
                let targetData = [];
                targetFinal?.map(item => {


                    if (targetData.hasOwnProperty("postpaidtarget")) {
                        targetData.postpaidtarget += parseInt(item.postpaidtarget);
                        targetData.prepaidtarget += parseInt(item.prepaidtarget);
                        targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
                        targetData.addon += parseInt(item.addon);
                        targetData.accessories += parseInt(item.accessories);
                        targetData.applecare += parseInt(item.applecare);
                        targetData.smssurvey += parseInt(item.smssurvey);
                    } else {
                        targetData.postpaidtarget = parseInt(item.postpaidtarget);
                        targetData.prepaidtarget = parseInt(item.prepaidtarget);
                        targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
                        targetData.addon = parseInt(item.addon);
                        targetData.accessories = parseInt(item.accessories);
                        targetData.applecare = parseInt(item.applecare);
                        targetData.smssurvey = parseInt(item.smssurvey);
                    }
                    return targetData;
                })

                const result = calculateCommissions(cisdatafinal, targetData);

                post += result.post
                pre += result.pre
                hup += result.hup
                add += result.addon
                ap += result.ap

                setCommissionFinal({
                    postpaidCommission: post,
                    prepaidCommission: pre,
                    hupCommission: hup,
                    addonCommission: add,
                    autopayCommission: ap,
                })
            });
        }
        else {

            const result = calculateCommissions(cisdata, targetRow);
            post += result?.post
            pre += result?.pre
            hup += result?.hup
            add += result?.addon
            ap += result?.ap

            setCommissionFinal({
                postpaidCommission: post,
                prepaidCommission: pre,
                hupCommission: hup,
                addonCommission: add,
                autopayCommission: ap,
            })
        }
    }, [cisdata, totalTarget])

    /* if(data.preCount < targetRow?.prepaidtarget * 0.90) {
        prepaidCommission = 0
    } else if(data.postCount < targetRow?.postpaidtarget * 0.85) {
        postpaidCommission = 0
    }
     else if(data.hupCount < targetRow?.hardwareupgrade * 0.75) {
        hupCommission = 0
    }
     else if(data.pppCount < targetRow?.addon * 0.65) {
        addonCommission = 0
    } */

    return (
        <div className='widget'>

            <div className="left">
                <span className="title">Postpaid</span>
                <span className="counter">${commissionFinal.postpaidCommission?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>

            <div className="left">
                <span className="title">Prepaid</span>
                <span className="counter">${commissionFinal.prepaidCommission?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>

            <div className="left">
                <span className="title">HUP</span>
                <span className="counter">${commissionFinal.hupCommission?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>

            <div className="left">
                <span className="title">AUTOPAY</span>
                <span className="counter">${commissionFinal.autopayCommission?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>
            <div className="left">
                <span className="title">Addons</span>
                <span className="counter">${commissionFinal.addonCommission?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>

            <div className="left">
                <span className="title">Total</span>
                <span className="counter">${(commissionFinal.postpaidCommission + commissionFinal.prepaidCommission + commissionFinal.hupCommission + commissionFinal.autopayCommission + commissionFinal.addonCommission)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>
        </div>
    )
}

export default Commissions