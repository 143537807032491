import React from 'react'
import './Employee.scss';
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
/* import CisUpload from '../../components/CisUpload/CisUpload' */
import EmpDashboard from '../../components/EmployeeDashboard/EmpDashboard'



const Employee = () => {


  return (
    <div className='employee'>
      <Sidebar />
      <div className="employeeContainer">
        <div className='navbarcontainer'>
          <Navbar />
        </div>
        <div className='empdashboard'>
          <EmpDashboard />
        </div>
      </div>
    </div>

  )
}

export default Employee