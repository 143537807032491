
function aggrestion(feature) {
    const aggregatedCommissions = {};

    // Iterate through the array
    feature.forEach(item => {
        const { commission, imei, phonenumber } = item;



        // Check if the key already exists in the aggregatedCommissions object
        if (aggregatedCommissions[phonenumber]) {
            // If it exists, add the commission to the existing entry
            aggregatedCommissions[phonenumber].commission += commission;
        } else {
            // If it doesn't exist, create a new entry with the commission
            aggregatedCommissions[phonenumber] = { commission, imei, phonenumber, transactiondate: item.transactiondate, accountnumber: item.accountnumber, activationDate: item.activationDate, store: item.store };
        }
    });

    // Convert the aggregatedCommissions object to an array
    const resultArray = Object.values(aggregatedCommissions);

    // Log the result array
    return resultArray
}

export function formatDate(inputDate) {
    // Split the input date string by '/'
    let dateParts = inputDate.split('/');

    // Extract year, month, and day from the split parts
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    // Format the date in the desired output format "YYYY-MM-DD"
    let formattedDate = year + '-' +
        ('0' + month).slice(-2) + '-' +
        ('0' + day).slice(-2);

    return formattedDate;
}


export default async function ScorecardCommission(event) {

    const postPaidActivations = [];
    const postPaidDeactivated = [];
    const prePaidActivations = [];
    const prePaid2Activations = [];
    const activationClawbacks = [];
    const postPaidFeatures = [];
    const postPaidFeatureRemove = [];
    const prePaidFeatures = [];
    const prePaidFeaturesRemove = [];
    const featuresClawbacks = [];
    const tabHandset = [];
    const tabHandsetClawbacks = [];
    const hardwareUpgrades = [];
    const hardwareUpgradesClawbacks = [];
    const spiffs = [];
    const spiffDeactivate = [];
    const spiffClawback = [];
    const deposits = [];
    const residualValue = [];
    for (let array = 0; array < event.length; array++) {


        if (event[array].EventType === "Post Paid Activation MRC") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue) * 3,
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                imei: event[array].CurrentIMEI,
                accountnumber: event[array].AccountNumber,
                store: event[array].Location,
                type: "postpaid"

            };
            postPaidActivations.push(newData);
        }
        else if (event[array].EventType === "Deposit Collection") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                imei: event[array].CurrentIMEI,
                store: event[array].Location,
            };
            deposits.push(newData);
        }
        else if (event[array].EventType === "Same Month Postpaid Deactivation MRC") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: -(parseInt(event[array].EventValue)),
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                imei: event[array].CurrentIMEI,
                activationdate: event[array].ActivationDate,
                store: event[array].Location,
                accountnumber: event[array].AccountNumber

            };
            postPaidDeactivated.push(newData);

        }
        else if (event[array].EventType === "Pre Paid Activation MRC") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseFloat(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                store: event[array].Location,
                planType: event[array].RatePlan,
                type: "prepaid"

            };
            prePaidActivations.push(newData);
        } else if (event[array].EventType === "Pre Paid Activation MRC 2") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue) * 0.66,
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                store: event[array].Location,
                type: "prepaid"
            };
            prePaid2Activations.push(newData);
        }
        else if (event[array].EventType === "Activation Clawback MRC - PostPaid" || event[array].EventType === "Prepaid Activation MRC - Buyer Remorse Adjustment" || event[array].EventType === "Activation Clawback MRC - PostPaid Suspend") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseFloat(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                activationDate: event[array].ActivationDate,
                store: parseInt(event[array].Location),
                sim: event[array].ICCID

            };
            activationClawbacks.push(newData);
        }
        else if (event[array].EventType === "Feature Activation MRC" || event[array].EventType === "Feature Add MRC") {
            if (event[array].RatePlan === "Phone Protection Plan") {
                const newData = {
                    phonenumber: event[array].PhoneNumber,
                    commission: parseInt(event[array].EventValue) * 3,
                    transactiondate: formatDate(event[array].TransactionDate),
                    imei: event[array].CurrentIMEI,
                    store: event[array].Location,
                    accountnumber: event[array].AccountNumber
                };
                postPaidFeatures.push(newData);
            } else {
                const newData = {
                    phonenumber: event[array].PhoneNumber,
                    commission: parseInt(event[array].EventValue),
                    transactiondate: formatDate(event[array].TransactionDate),
                    imei: event[array].CurrentIMEI,
                    store: event[array].Location,
                    accountnumber: event[array].AccountNumber
                };
                postPaidFeatures.push(newData);
            }

        }
        else if (event[array].EventType === "Same Month Feature Remove MRC" || event[array].EventType === "Same Month Feature Deactivation MRC") {
            if (event[array].RatePlan === "Phone Protection Plan") {
                const newData = {
                    phonenumber: event[array].PhoneNumber,
                    commission: parseInt(event[array].EventValue) * 3,
                    transactiondate: formatDate(event[array].TransactionDate),
                    activationDate: event[array].ActivationDate,
                    store: event[array].Location,
                };
                postPaidFeatureRemove.push(newData);
            } else {
                const newData = {
                    phonenumber: event[array].PhoneNumber,
                    commission: parseInt(event[array].EventValue),
                    transactiondate: formatDate(event[array].TransactionDate),
                    activationDate: event[array].ActivationDate,
                    store: event[array].Location,
                };
                postPaidFeatureRemove.push(newData);
            }
        }
        else if ((event[array].EventType === "Prepaid Feature Add MRC" || event[array].EventType === "Prepaid Feature Activation MRC") && event[array].RatePlan !== "Phone Protection Plan") {

            const newData = {

                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,


            };
            prePaidFeatures.push(newData);
        }
        else if ((event[array].EventType === "Same Month PrePaid Feature Remove MRC")) {

            const newData = {

                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,

            };
            prePaidFeaturesRemove.push(newData);
        }
        else if (event[array].EventType === "Feature MRC Clawback") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            featuresClawbacks.push(newData);
        }
        else if (event[array].EventType === "TAB Handset" || event[array].EventType === "TAB Handset Add" || event[array].EventType === "TAB Handset Downgrade" || event[array].EventType === "TAB Handset Upgrade" || event[array].EventType === "TAB Handset Sidegrade") {
            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseFloat(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            tabHandset.push(newData);
        }
        else if (event[array].EventType === "TAB Residual Value") {
            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseFloat(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            residualValue.push(newData);
        }
        else if (event[array].EventType === "TAB Handset Return Clawback Amount"
            || event[array].EventType === "TAB Handset Downgrade Clawback Amount"
            || event[array].EventType === "TAB Handset Upgrade Clawback Amount"
            || event[array].EventType === "TAB Handset Sidegrade Clawback Amount"
            || event[array].EventType === "TAB Handset Cancel Clawback Amount") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseFloat(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            tabHandsetClawbacks.push(newData);
        }
        else if (event[array].EventType === "Hardware Upgrade") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: 75,
                transactiondate: formatDate(event[array].TransactionDate),
                sim: event[array].ICCID,
                store: event[array].Location,
                type: "hup"

            };
            hardwareUpgrades.push(newData);
        }
        else if (event[array].EventType === "Hardware Upgrade Return Clawback Amount" || event[array].EventType === "Hardware Upgrade Swap Clawback Amount") {

            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            hardwareUpgradesClawbacks.push(newData);
        }
        else if (event[array].EventType === "Postpaid Activation Spiff Amount" && event[array].RatePlan !== "Promo Apple Watch 1GB") {
            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            spiffs.push(newData);
        }
        else if (event[array].EventType === "Postpaid Activation Spiff - Same Month Deactivation Amount" && event[array].RatePlan !== "Promo Apple Watch 1GB") {
            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            spiffDeactivate.push(newData);
        }
        else if (event[array].EventType === "Postpaid Activation Spiff - Clawback Amount" && event[array].RatePlan !== "Promo Apple Watch 1GB") {
            const newData = {
                name: event[array].CustomerName,
                phonenumber: event[array].PhoneNumber,
                commission: parseInt(event[array].EventValue),
                transactiondate: formatDate(event[array].TransactionDate),
                store: event[array].Location,
                sim: event[array].ICCID

            };
            spiffClawback.push(newData);
        }
    }

    //Postpaid

    residualValue.forEach(residual => {
        tabHandset.forEach(handset => {
            if (handset.phonenumber === residual.phonenumber) {
                handset.commission += residual.commission;
            }
        });
    });


    let postAfterSameMonth = [];

    postPaidActivations.forEach(val => {

        let exist = postPaidDeactivated.find(data => (data.phonenumber === val.phonenumber && data.name === val.name && data.accountnumber === val.accountnumber));

        if (!exist) {
            postAfterSameMonth.push(val);
        }

    })

    const prepaid = prePaidActivations.map((item) => {

        if (item.planType === "By The Year 4G Nationwide Talk Text"
            || item.planType === "By The Year 4G 12GB + Nationwide Talk and Text"
            || item.planType === "By The Year 12GB + Nationwide Talk Text"
            || item.planType === "By The Year 4G Nationwide 12GB"
            || item.planType === "By The Year 4G Talk"
            || item.planType === "By The Year 4G Nationwide 20GB"
            || item.planType === "By The Year 4G Nationwide 30GB") {
            return {
                ...item,
                commission: item.commission,
            };
        } else if (item.planType === "By The Year 4G Nationwide Talk") {
            return {
                ...item,
                commission: 25,
            };
        } else if (item.planType === "By The Year 4G Nationwide 15GB") {
            return {
                ...item,
                commission: 30,
            };
        } else if (item.planType === "By The Year Nationwide 30GB") {
            return {
                ...item,
                commission: 37,
            };
        }
        else {
            return {
                ...item,
                commission: item.commission * 1.34,
            };
        }
    })

    // adding features commission of same number postpaid
    let reducePostpaidFeature = aggrestion(postPaidFeatures);

    // adding commission of same number of Postpaid Feature Remove
    let reducePostpaidFeatureRemove = aggrestion(postPaidFeatureRemove);

    let postFeatures = reducePostpaidFeature.map(val => {
        let exist = reducePostpaidFeatureRemove.find(item => item.phonenumber === val.phonenumber)
        if (exist) {
            return {
                ...val, commission: val.commission - exist?.commission
            }
        }
        return val;
    })

    let reducePrepaidFeature = aggrestion(prePaidFeatures);

    let reducePrepaidFeatureRemove = aggrestion(prePaidFeaturesRemove);

    let preFeatures = reducePrepaidFeature.map(val => {
        let exist = reducePrepaidFeatureRemove.find(item => item.phonenumber === val.phonenumber)
        if (exist) {
            return {
                ...val, commission: val.commission - exist?.commission
            }
        }
        return val;
    })

    let hardwareUpgradeFinal = [];
    hardwareUpgrades.forEach(val => {
        let exist = hardwareUpgradesClawbacks.some(data => (data.phonenumber === val.phonenumber && data.name === val.name && data.accountnumber === val.accountnumber && data.transactiondate !== val.transactiondate));
        if (!exist) {
            hardwareUpgradeFinal.push(val);
        }
    })

    const actForCommission = [...postPaidActivations, ...prepaid, ...hardwareUpgrades]
    const scoreCardDeact = [...postPaidDeactivated, ...hardwareUpgradesClawbacks]
    return {
        postAfterSameMonth: postAfterSameMonth,
        prepaid: prepaid,
        postFeatures: postFeatures,
        preFeatures: preFeatures,
        postPaidDeactivated: postPaidDeactivated,
        activationClawbacks: activationClawbacks,
        postPaidFeatureRemove: postPaidFeatureRemove,
        prePaidFeaturesRemove: prePaidFeaturesRemove,
        tabHandset: tabHandset,
        hardwareUpgradesClawbacks: hardwareUpgradesClawbacks,
        tabHandsetClawbacks: tabHandsetClawbacks,
        hardwareUpgradeFinal: hardwareUpgradeFinal,
        featuresClawbacks: featuresClawbacks,
        actForCommission: actForCommission,
        scoreCardDeact: scoreCardDeact,
        prePaid2Activations: prePaid2Activations,
        postPaidActivations: postPaidActivations,
        deposits: deposits,
        hardwareUpgrades: hardwareUpgrades,


    }

}