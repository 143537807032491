import React, { useState, useEffect } from 'react'
import './EditProfile.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import { useAuthStore } from '../../store/store';
import { getUser, updateUserProfilePicture } from '../../helper/helper'
import toast, { Toaster } from 'react-hot-toast'
import convertToBase64 from '../../helper/convert';
import avatar from '../../assets/profile.png';
import { getValues } from '../ReusableFunctions'

const EditProfile = () => {
  const { username } = useAuthStore(state => state.auth);
  const [apiData, setApiData] = useState('');
  const [file, setFile] = useState();
  const [Image, setImage] = useState('')

  const values = getValues(apiData.stores)

  useEffect(() => {
    let userdata = getUser({ username: username });
    userdata.then(res => {
      let apiData = res.data;
      setApiData(apiData)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const maxSizeBytes = 100 * 1024;
    if (username) {
      if (Image.size > maxSizeBytes) {

        alert('Image size exceeds the maximum allowed size (100 KB).');
        setFile(null);
        setImage(null);
      } else {
        let profilePicture = file || '';
        let updatePromise = updateUserProfilePicture({ profilePicture: profilePicture, username: username });

        toast.promise(updatePromise, {
          loading: 'Updating...',
          success: <b>Update Successfully...!</b>,
          error: <b>Could not Update!</b>
        });
      }
    }
  }
  /** formik doensn't support file upload so we need to create this handler */
  const onUpload = async e => {
    setImage(e.target.files[0])
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
  }

  return (
    <div className='edit-profile'>
      {apiData.role === 'admin' && <AdminSidebar />}
      {apiData.role !== 'admin' && <Sidebar />}

      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <div className="edit-password-main">
        <div className='navbarcontainer'>
          <Navbar />
        </div>
        <div className="edit-password-container">
          {/* <div className="edit-profile-top">
            <h2>Profile</h2>
          </div> */}

          <form onSubmit={handleSubmit}>
            <div className="form-top">
              <label htmlFor="profile">
                <img
                  src={apiData?.profilePicture || file || avatar}
                  className='form-top-img'
                  alt="avatar"
                />
              </label>
              <input hidden onChange={onUpload} type="file" id='profile' name='profile' />
            </div>

            <div className="form-bot">

              <input value={apiData.fullname || ''} type="text" placeholder='FullName' readOnly />
              <input value={apiData.username || ''} type="text" placeholder='UserName' readOnly />
              <input value={apiData.email || ''} type="text" placeholder='Email' readOnly />
              <input value={apiData.role || ''} type="text" placeholder='Role' readOnly />
              <input value={apiData.phonenumber || ''} type="text" placeholder='PhoneNumber' readOnly />
              <input value={apiData.employeeid || ''} type="text" placeholder='EmployeeId' readOnly />
              <input value={values || ''} type="text" placeholder='Stores' readOnly />
              <input value={apiData.dob || ''} type="text" placeholder='DOB' readOnly />
              <div className='button'>
                <button type='submit'>Update</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>

  )
}

export default EditProfile