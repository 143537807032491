import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const blockBackNavigation = useRef(false); // To track when to block back navigation

    useEffect(() => {

        const pathsToProtect = ['/adduser', '/targets', '/cisupload', '/refund', '/homeinternet', '/eventcis']; // Add paths where you want to block back navigation
        const handleBeforeUnload = (event) => {
            if (pathsToProtect.includes(location.pathname)) {
                event.preventDefault();
                event.returnValue = "Are you sure you want to leave? You will lost the data entered.";
            }
        };

        const handlePopState = (event) => {
            if (pathsToProtect.includes(location.pathname)) {
                if (!blockBackNavigation.current) {
                    const confirmNavigate = window.confirm("Are you sure you want to leave? You will lost the data entered.");
                    if (!confirmNavigate) {
                        blockBackNavigation.current = true;
                        navigate(1); // Move them forward to the current page
                    } else {
                        // Get the user role (assuming it's stored in localStorage)
                        const userRole = localStorage.getItem('role');

                        if (userRole === 'employee') {
                            navigate('/employee');
                        } else if (userRole === 'manager') {
                            navigate('/manager');
                        } else if (userRole === 'admin') {
                            navigate('/admin')
                        }
                    }
                } else {
                    blockBackNavigation.current = false;
                }
            }
        };

        // Push current state to prevent back navigation
        window.history.pushState(null, '', location.pathname);

        // Add event listeners
        window.addEventListener('beforeunload', handleBeforeUnload); // Prompt when closing or reloading
        window.addEventListener('popstate', handlePopState);         // Back button behavior

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, location.pathname]);

    return null;
};

export default NavigationGuard;
