import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import './AdminHome.scss'
import AdminDashboard from '../../components/AdminDashboard/AdminDashboard'


const AdminHome = () => {
  return (
    <>
      <div className='admin'>
        <AdminSidebar />
        <div className="admincontainer">
          <div className="navbarcontainer">
            <Navbar />
          </div>
          <div className="admindashboard">
            <AdminDashboard />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminHome