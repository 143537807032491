import React, { useState, useEffect } from 'react'
import './AttachRate.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import ReactLoading from 'react-loading';
import { getAttachRate, getAllUsersData, getUser, getFilterdByDate, getOnlyAccessory, getHoursWorkedData } from '../../helper/helper'
import { useAuthStore } from '../../store/store';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Sidebar from '../../components/Sidebar/Sidebar'
import { cisCommisson, calculateStoreCommissionAfterCisCommission } from '../../components/ReusableFunctions'
import { format, startOfMonth, endOfMonth } from 'date-fns';
import Button from '@mui/material/Button';
import toast, { Toaster } from 'react-hot-toast';

const AttachRate = () => {

    const [arApiData, setArApiData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [aRArray, setARArray] = useState([])
    const [aRArray1, setARArray1] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [userdata, setUserdata] = useState([]);
    const [filterEmp, setFilterEmp] = useState('');
    const [storeAttachData, setStoreAttachData] = useState(null)
    const [storesList, setStoresList] = useState([])
    const [finalARData, setFinalARData] = useState([]);
    const [userList, setuserList] = useState([])
    const [cisdata, setCisdata] = useState([]);
    const [Rev, setRev] = useState(null);
    const [OnlyAccData, setOnlyAccData] = useState([])
    const [OAfiltered, setOAfiltered] = useState({})
    const [hoursAPI, setHoursAPI] = useState([])
    const { username } = useAuthStore(state => state.auth);
    const [isScrolling, setIsScrolling] = useState(false);

    const generateMonths = () => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 5; i++) { // Generate the last 5 years
            years.push(currentYear + i);
        }
        return years;
    };

    const months = generateMonths();
    const years = generateYears();
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [employeName, setEmployeName] = useState("");
    const [storeName, setStoreName] = useState("");

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getAllUsersData();
            setuserList(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getFilterdByDate({ startDate, endDate });
            setCisdata(data)
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getOnlyAccessory({ startDate, endDate });
            let filtered = [];
            data.forEach(obj => {
                filtered.push(obj.attachmentRateOnlyAccessory)
            })
            setOnlyAccData(filtered)
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        async function fetchUser() {
            const { data } = await getHoursWorkedData({ startDate, endDate });
            setHoursAPI(data)
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        async function fetchUser() {
            const { data } = await getAttachRate({ startDate, endDate });
            setArApiData(data);
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(true);

            // Set a timeout to reset scrolling state after the scroll ends
            setTimeout(() => {
                setIsScrolling(false);
            }, 3000); // Adjust the delay as necessary
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton" || "Center Mall at Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };
    const storeNameList2 = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall at Barton",
        TCPNFIN: "Town Centre Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 7) {
            return storeNameList[string]
        } else {
            let user = []
            for (let key in userdata) {
                if (userdata[key].username === string) {
                    user.push(userdata[key].fullname)
                }
            }
            return user.length > 0 ? user[0] : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11)
        }
    };

    function attachrateCalculator(groupedBySpecialist) {
        const groupedBySpecialistmerged = {};
        for (let key in groupedBySpecialist) {
            let hupCount = 0;
            let hupSum = { cost: 0, msrp: 0, discount: 0 };
            let actCount = 0;
            let actSum = { cost: 0, msrp: 0, discount: 0 };
            let actWithoutAcc = 0;
            let hupWithoutAcc = 0;
            let accActNumber = 0;
            let accHupNumber = 0;
            let actPPP = 0;
            let hupPPP = 0;
            let actWDP = 0;
            groupedBySpecialist[key].forEach(obj => {
                const { aaData, connectionType, simNumber, attachedAcc, phoneProtectionPlan, imei } = obj;
                const { cost, msrp, discount } = aaData;
                let accNo = attachedAcc.length

                if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A")) {
                    actWithoutAcc++;
                } else if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A")) {
                    hupWithoutAcc++;
                } else {
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A") {
                        hupCount++;
                        hupSum.cost += cost;
                        hupSum.msrp += msrp;
                        hupSum.discount += discount;
                        accHupNumber += accNo
                        if (phoneProtectionPlan === "Accepted") {
                            hupPPP++
                        }
                    } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A") {
                        actCount++;
                        actSum.cost += cost;
                        actSum.msrp += msrp;
                        actSum.discount += discount;
                        accActNumber += accNo
                        if (phoneProtectionPlan === "Accepted") {
                            actPPP++
                        }
                        if (imei !== "N/A") {
                            actWDP++
                        }
                    }
                }
            });

            let bestMatch = null;
            hoursAPI[0]?.storeHours.forEach(item => {
                const name = item[0];
                if (storeNameList2[key] === name) {
                    bestMatch = item[1];
                }
            });

            groupedBySpecialistmerged[key] = {
                act: actWithoutAcc + actCount,
                actWithoutAcc,
                actCount,
                hup: hupCount + hupWithoutAcc,
                hupCount,
                hupWithoutAcc,
                actSf: actSum.msrp - actSum.discount,
                actSum,
                hupSum,
                hupSf: hupSum.msrp - hupSum.discount,
                actPm: (actSum.msrp - actSum.discount) - actSum.cost,
                hupPm: (hupSum.msrp - hupSum.discount) - hupSum.cost,
                actARate: actCount / (actWithoutAcc + actCount) * 100,
                hupARate: hupCount / (hupCount + hupWithoutAcc) * 100,
                accHupNumber,
                accActNumber,
                accActNumRate: (accActNumber / (actWithoutAcc + actCount)),
                accHupNumRate: (accHupNumber / (hupCount + hupWithoutAcc)),
                actPPP: actPPP,
                hupPPP: hupPPP,
                actPPPRate: actPPP / (actWithoutAcc + actCount) * 100,
                hupPPPRate: hupPPP / (hupCount + hupWithoutAcc) * 100,
                actWD: actWDP,
                actWDRate: actWDP / (actWithoutAcc + actCount) * 100,
                Comm: Rev[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount),
                hours: bestMatch,
                actperH: bestMatch / (actWithoutAcc + actCount + hupCount + hupWithoutAcc),
                revperH: (Rev[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount)) / bestMatch,
            }
        }
        return groupedBySpecialistmerged
    }

    /* const removeDuplicatesByInvoice = (array) => {
        const seenInvoices = new Set();
        return array.filter(item => {
            if (!item.saleInvoice) {
                return true; // Keep objects without saleInvoice
            }
            const duplicate = seenInvoices.has(item.saleInvoice);
            seenInvoices.add(item.saleInvoice);
            return !duplicate;
        });
    }; */
    const removeDuplicatesByInvoice = (array, invoiceKey = 'saleInvoice') => {
        const seenInvoices = new Set();
        return array.filter(item => {
            if (!item[invoiceKey]) {
                return true; // Keep objects without the invoice key
            }
            const duplicate = seenInvoices.has(item[invoiceKey]);
            seenInvoices.add(item[invoiceKey]);
            return !duplicate;
        });
    };
    function groupByProperty(data, property) {
        return data.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    const handleSearch = async () => {
        setIsLoading(true);
        const uniqueArray = removeDuplicatesByInvoice(arApiData);
        if (uniqueArray.length > 0) {
            if (userDetail?.role === 'manager') {
                let finalArray = []
                userDetail?.stores?.split(',').forEach(store => {
                    userList.forEach(user => {
                        let exist = user.stores.split(',').some(item => item === store)
                        if (exist) {
                            if (!finalArray.includes(user)) {
                                finalArray.push(user)
                            }
                        }
                    })
                })
                setUserdata(finalArray);
                let storesList = userDetail?.stores?.split(',')
                setStoresList(storesList)
                let employeeList = finalArray?.map(emp => emp.username);
                setFilterEmp(employeeList);

                let filterAR = []
                storesList.forEach(store => {
                    uniqueArray.forEach(obj => {
                        const { storeInvoice } = obj;
                        if (storeInvoice === store) {
                            filterAR.push(obj)
                        }
                    })
                })
                let matchingInvoices = OnlyAccData.filter(item => {
                    // Extract the prefix part of the saleInvoiceOA
                    let prefix = item.saleInvoiceOA.match(/^[a-zA-Z]+/)[0];
                    // Check if the extracted prefix matches any prefix in array2
                    return storesList.some(prefixInArray => prefix === prefixInArray);
                }).map(item => item);
                setFinalARData(filterAR)
                setOnlyAccData(matchingInvoices)
            } else if (userDetail?.role === 'admin') {
                setUserdata(uniqueArray);
                let filterStores = []
                let storesList = uniqueArray?.map(store => store.storeInvoice);
                storesList?.forEach(val => {
                    let isExist = filterStores.some(store => store === val);
                    if (!isExist) filterStores.push(val);
                })
                setStoresList(filterStores)
                let filterEmployee = []
                let employeeList = uniqueArray?.map(emp => emp.user);
                employeeList?.forEach(val => {
                    let isExist = filterEmployee.some(employee => employee === val);
                    if (!isExist) filterEmployee.push(val);
                })
                setFilterEmp(filterEmployee);
                setFinalARData(uniqueArray)
            } else if (userDetail?.role === 'employee') {
                let filterAR = []
                uniqueArray.forEach(obj => {
                    if (obj.user === userDetail.username) {
                        filterAR.push(obj)
                    }
                })

                let storesList = userDetail?.stores?.split(',')
                let matchingInvoices = []
                OnlyAccData.forEach(item => {
                    if (item.username === userDetail.username) {
                        matchingInvoices.push(item)
                    }
                })
                setFinalARData(filterAR)
                setStoresList(storesList)
                setOnlyAccData(matchingInvoices)
            }
        } else {
            toast.error("No Attach Rate Data is Present to Show")
            setIsLoading(false);
        }
    }

    if ((isLoading && storesList?.length > 0) && cisdata?.length > 0) {

        setIsLoading(false);
        if (finalARData?.length > 0) {

            const attachedRateGroupedBySpecialist = {};
            const attachedRateGroupedByStore = {};
            let oAEmpList = {};
            let oAStoreList = {};

            const CisDataGroupedByUser = groupByProperty(cisdata, 'user');
            const CisDataGroupedByStoreInvoice = groupByProperty(cisdata, 'storeInvoice');
            finalARData.forEach(obj => {
                const { user, ...rest } = obj;
                if (!attachedRateGroupedBySpecialist[user]) {
                    attachedRateGroupedBySpecialist[user] = [rest];
                } else {
                    attachedRateGroupedBySpecialist[user].push(rest);
                }
                const { storeInvoice, ...rest1 } = obj;
                if (!attachedRateGroupedByStore[storeInvoice]) {
                    attachedRateGroupedByStore[storeInvoice] = [rest1];
                } else {
                    attachedRateGroupedByStore[storeInvoice].push(rest1);
                }
            });
            OnlyAccData.forEach(obj => {
                const { username, ...rest } = obj;

                if (!oAEmpList[username]) {
                    oAEmpList[username] = [rest]
                } else {
                    oAEmpList[username].push(rest)
                }
            })

            const defaultValue = {
                aaDataOnlyAccessory: { cost: 0, msrp: 0, discount: 0 },
                attachedAccOnlyAccessory: [],
                saleInvoiceOA: "",
                salesPerson: "",
                storeInvoiceOA: "",
                tenderedDateOA: "",
                timeOA: ""
            };
            userList.forEach(user => {
                if (!oAEmpList[user.username]) {
                    oAEmpList[user.username] = [defaultValue];
                }
            });

            OnlyAccData.forEach(obj => {
                const { saleInvoiceOA, ...rest } = obj;
                const storeInvoice = saleInvoiceOA.slice(0, 7);
                if (!oAStoreList[storeInvoice]) {
                    oAStoreList[storeInvoice] = [rest]
                } else {
                    oAStoreList[storeInvoice].push(rest)
                }
            })
            setOAfiltered(oAStoreList)
            setStoreAttachData(attachedRateGroupedByStore)
            let emplevelCommission = {}
            let storelevelCommission = {}

            for (let key in CisDataGroupedByUser) {
                const CisDataGroupedByUserAfterduplicateremoved = removeDuplicatesByInvoice(CisDataGroupedByUser[key]);
                let computedCustomerList = cisCommisson(CisDataGroupedByUserAfterduplicateremoved);
                let finalComm = calculateStoreCommissionAfterCisCommission(computedCustomerList)
                let { postpaidCommission, prepaidCommission, hupCommission, hupMargin, addonCommission, deposit } = finalComm
                emplevelCommission[key] = addonCommission + hupCommission + hupMargin + postpaidCommission + prepaidCommission - deposit
            }
            for (let key in CisDataGroupedByStoreInvoice) {
                const CisDataGroupedByStoreInvoiceAfterduplicateremoved = removeDuplicatesByInvoice(CisDataGroupedByStoreInvoice[key]);
                let computedCustomerList = cisCommisson(CisDataGroupedByStoreInvoiceAfterduplicateremoved);
                let finalComm = calculateStoreCommissionAfterCisCommission(computedCustomerList)
                let { addonCommission, hupCommission, hupMargin, postpaidCommission, prepaidCommission, deposit } = finalComm
                storelevelCommission[key] = addonCommission + hupCommission + hupMargin + postpaidCommission + prepaidCommission - deposit
            }
            setRev(storelevelCommission)
            const attachedRateGroupedBySpecialistmerged = {};
            console.log(attachedRateGroupedBySpecialist["navjot@vyadom.com"], oAEmpList["navjot@vyadom.com"])
            for (let key in attachedRateGroupedBySpecialist) {
                let hupCount = 0;
                let hupSum = { cost: 0, msrp: 0, discount: 0 };
                let actCount = 0;
                let actSum = { cost: 0, msrp: 0, discount: 0 };
                let actWithoutAcc = 0;
                let hupWithoutAcc = 0;
                let accActNumber = 0;
                let accHupNumber = 0;
                let actPPP = 0;
                let hupPPP = 0;
                let actWDP = 0;

                attachedRateGroupedBySpecialist[key].forEach(obj => {
                    const { aaData, connectionType, simNumber, attachedAcc, phoneProtectionPlan, imei } = obj;
                    const { cost, msrp, discount } = aaData;
                    let accNo = attachedAcc.length

                    if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A")) {
                        actWithoutAcc++;
                    } else if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A")) {
                        hupWithoutAcc++;
                    } else {
                        if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A") {
                            hupCount++;
                            hupSum.cost += cost;
                            hupSum.msrp += msrp;
                            hupSum.discount += discount;
                            accHupNumber += accNo
                        } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A") {
                            actCount++;
                            actSum.cost += cost;
                            actSum.msrp += msrp;
                            actSum.discount += discount;
                            accActNumber += accNo
                        }
                    }
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A" && phoneProtectionPlan === "Accepted") {
                        hupPPP++
                    } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A" && phoneProtectionPlan === "Accepted") {
                        actPPP++
                    }
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A" && imei !== "N/A") {
                        actWDP++
                    }
                });
                const emailPrefix = key.split('@')[0].toLowerCase();
                const matchScore = (emailPrefix, name) => {
                    let score = 0;
                    let emailIndex = 0;
                    for (let i = 0; i < name.length; i++) {
                        if (name[i].toLowerCase() === emailPrefix[emailIndex]) {
                            emailIndex++;
                            score += 10; // Add a base score for each sequential match
                        } else {
                            score -= 1; // Penalize for non-matching characters
                        }
                        if (emailIndex === emailPrefix.length) {
                            // Bonus for matching the entire prefix early
                            score += 50 - i; // Higher score for matches that occur earlier
                            break;
                        }
                    }
                    return score;
                };
                // Find the best match
                let bestMatch = null;
                let bestScore = -Infinity;
                hoursAPI[0]?.empHours.forEach(item => {
                    const [name] = item;
                    const score = matchScore(emailPrefix, name);
                    if (score > bestScore) {
                        bestScore = score;
                        bestMatch = item;
                    }
                });
                // Result
                const result = bestMatch ? bestMatch[1] : null;

                attachedRateGroupedBySpecialistmerged[key] = {
                    act: actWithoutAcc + actCount,
                    actWithoutAcc,
                    actCount,
                    hup: hupCount + hupWithoutAcc,
                    hupCount,
                    hupWithoutAcc,
                    actSf: actSum.msrp - actSum.discount,
                    actSum,
                    hupSum,
                    hupSf: hupSum.msrp - hupSum.discount,
                    actPm: (actSum.msrp - actSum.discount) - actSum.cost,
                    hupPm: (hupSum.msrp - hupSum.discount) - hupSum.cost,
                    actARate: actCount / (actWithoutAcc + actCount) * 100,
                    hupARate: hupCount / (hupCount + hupWithoutAcc) * 100,
                    accHupNumber,
                    accActNumber,
                    accActNumRate: (accActNumber / (actWithoutAcc + actCount)),
                    accHupNumRate: (accHupNumber / (hupCount + hupWithoutAcc)),
                    actPPP: actPPP,
                    hupPPP: hupPPP,
                    actPPPRate: actPPP / (actWithoutAcc + actCount) * 100,
                    hupPPPRate: hupPPP / (hupCount + hupWithoutAcc) * 100,
                    actWD: actWDP,
                    actWDRate: actWDP / (actWithoutAcc + actCount) * 100,
                    Comm: emplevelCommission[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount),
                    hours: result,
                    actperH: result / (actWithoutAcc + actCount + hupCount + hupWithoutAcc),
                    revperH: (emplevelCommission[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount)) / result
                }
            }

            const groupedByAccmerged = {};

            for (let key in oAEmpList) {
                const OADataAfterduplicateremoved = removeDuplicatesByInvoice(oAEmpList[key], "saleInvoiceOA");

                let accSum = { cost: 0, msrp: 0, discount: 0 };
                let OAtotal = 0;
                OADataAfterduplicateremoved.forEach(obj => {
                    const { aaDataOnlyAccessory, attachedAccOnlyAccessory, /* saleInvoiceOA, storeInvoiceOA */ } = obj;
                    const { cost: costOA, msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
                    let OANumber = attachedAccOnlyAccessory.length
                    accSum.cost += costOA
                    accSum.msrp += msrpOA
                    accSum.discount += discountOA
                    OAtotal += OANumber
                })
                groupedByAccmerged[key] = {
                    OACost: accSum.cost,
                    OAMsrp: accSum.msrp,
                    OADiscount: accSum.discount,
                    OASF: accSum.msrp - accSum.discount,
                    OAPM: (accSum.msrp - accSum.discount) - accSum.cost,
                    OAtotal
                }
            }
            const updatedObj1 = { ...attachedRateGroupedBySpecialistmerged };
            Object.keys(groupedByAccmerged).forEach(key => {
                if (updatedObj1[key]) {
                    // If key exists in obj1, add properties from ob2
                    updatedObj1[key] = { ...updatedObj1[key], ...groupedByAccmerged[key] };
                    updatedObj1[key] = {
                        ...updatedObj1[key],
                        OAplusComm: updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0),
                        OAPlusrevperH: (updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0)) / updatedObj1[key].hours
                    }
                }
            });
            const entries = Object.entries(updatedObj1);
            entries.sort((a, b) => b[1].OAPlusrevperH - a[1].OAPlusrevperH);
            const sortedData = Object.fromEntries(entries);

            setARArray(sortedData);
            setARArray1(sortedData);
        }
    }

    const handleEmployeeNameChange = (e) => {
        setEmployeName(e.target.value)
        setStoreName("")
        e.preventDefault();
        const { value } = e.target
        if (value !== "") {
            const obj = {};
            Object.keys(aRArray).map(name => {
                if (capitalizeFirstLetter(name) === capitalizeFirstLetter(value)) {
                    obj[name] = aRArray[name]
                }
            })
            setARArray1(obj);
            let cisEmpList = [];
            cisdata.forEach(cis => {
                if (cis.user === value) {
                    cisEmpList.push(cis);
                }
            })
        } else {
            setARArray1(aRArray);
        }
    }

    const handleStoreNameChange = (e) => {
        setStoreName(e.target.value)
        setEmployeName("")
        e.preventDefault();
        const { value } = e.target;
        let data = attachrateCalculator(storeAttachData)
        const groupedByAccmerged = {};
        for (let key in OAfiltered) {
            const OADataAfterduplicateremoved = removeDuplicatesByInvoice(OAfiltered[key], "saleInvoiceOA");
            let accSum = { cost: 0, msrp: 0, discount: 0 };
            let OAtotal = 0;
            OADataAfterduplicateremoved.forEach(obj => {
                const { aaDataOnlyAccessory, attachedAccOnlyAccessory, /* saleInvoiceOA, storeInvoiceOA  */ } = obj;
                const { cost: costOA, msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
                let OANumber = attachedAccOnlyAccessory.length
                accSum.cost += costOA
                accSum.msrp += msrpOA
                accSum.discount += discountOA
                OAtotal += OANumber
            })
            groupedByAccmerged[key] = {
                OACost: accSum.cost,
                OAMsrp: accSum.msrp,
                OADiscount: accSum.discount,
                OASF: accSum.msrp - accSum.discount,
                OAPM: (accSum.msrp - accSum.discount) - accSum.cost,
                OAtotal
            }
        }
        if (value !== "") {
            const obj = {};
            const obj2 = {};
            Object.keys(data).map(name => {
                if (name === value) {
                    obj[name] = data[name]
                }
            })
            Object.keys(groupedByAccmerged).map(name => {
                if (name === value) {
                    obj2[name] = groupedByAccmerged[name]
                }
            })
            const combObj = {}
            combObj[value] = { ...obj[value], ...obj2[value] }
            combObj[value] = {
                ...combObj[value],
                OAplusComm: combObj[value].Comm + (combObj[value]?.OASF || 0),
                OAPlusrevperH: (combObj[value].Comm + (combObj[value]?.OASF || 0)) / combObj[value].hours
            }
            setARArray1(combObj);
        } else {
            const updatedObj1 = { ...data };
            Object.keys(updatedObj1).forEach(key => {
                if (updatedObj1[key]) {
                    // If key exists in obj1, add properties from ob2
                    updatedObj1[key] = { ...updatedObj1[key], ...groupedByAccmerged[key] };
                    updatedObj1[key] = {
                        ...updatedObj1[key],
                        OAplusComm: updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0),
                        OAPlusrevperH: (updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0)) / updatedObj1[key].hours
                    }
                }
            });
            const entries = Object.entries(updatedObj1);
            entries.sort((a, b) => b[1].OAPlusrevperH - a[1].OAPlusrevperH);
            const sortedData = Object.fromEntries(entries);
            setARArray1(sortedData);
        }
    }

    const roundValues = (dataArray, keys) => {
        return dataArray.map(item => {
            keys.forEach(key => {
                if (item.hasOwnProperty(key)) {
                    item[key] = parseFloat(item[key].toFixed(2));
                }
            });
            return item;
        });
    };

    const headers = [
        { key: 'Act', label: 'Activation' },
        { key: 'AA', label: 'Accessories Attached' },
        { key: 'AR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profilt Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per Activation' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'WD', label: 'With Device' },
        { key: 'WDAtt', label: 'With Device Attach' },
        { key: 'HRS', label: 'Hours' },
        { key: 'AH', label: 'Activity Per Hour' },
        { key: 'Rev', label: 'Revenue' },
        { key: 'RH', label: 'Revenue Per Hour' }
    ];

    const headersbot = [
        { key: 'Hup', label: 'Hardware Upgrade' },
        { key: 'HA', label: 'Accessories Attached' },
        { key: 'HR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profilt Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per HUP' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'OA No', label: 'Only Accesorries' },
        ...(userDetail?.role === 'admin' ? [{ key: 'OA PM', label: 'Only Accesorries Profit Margin' },] : []),


    ];

    const downloadCSV = () => {
        const data = Object.keys(aRArray1).map(email => ({
            email,
            ...aRArray1[email],
        }));
        const uniqueEmails = [...new Set(data.map(item => item.email))];
        const outputs = uniqueEmails.map((email, index) => {
            const filteredData = data.filter(item => item.email === email);
            let store = (data) => {
                return [{ email: storeNameList[data] }]
            }
            let emailOutput = [{ email }];
            let data1 = emailOutput[0].email.length === 7 ? store(emailOutput[0].email) : emailOutput
            const actKeys = [{
                act: 'Act',
                actCount: 'Accessory Attached',
                actARate: 'Attach Rate(%)',
                actCost: 'Cost($)',
                actMSRP: 'MSRP($)',
                actDiscount: 'Discount($)',
                actSf: 'Sold for($)',
                actPm: 'Profit Margin($)',
                accActNumber: 'Accessories Numbers',
                accActNumRate: 'Accessories Numbers Rate(%)'
            }];
            const actOutput = filteredData.map(({ act, actCount, actARate, actSum, actSf, actPm, accActNumber, accActNumRate }) => ({
                act,
                actCount,
                actARate,
                ...actSum,
                actSf,
                actPm,
                accActNumber,
                accActNumRate
            }));
            const keysToRound = [
                'accActNumRate', 'cost', 'discount', 'actARate', 'actPm', 'actSf', 'msrp'
            ];
            const roundedDataAct = roundValues(actOutput, keysToRound);
            const hupKeys = [{ hup: 'Hup', hupCount: 'Accessory Attached', hupAr: 'Attach Rate(%)', hupCost: 'Cost($)', hupMSRP: 'MSRP($)', hupDiscount: 'Discount($)', hupSf: 'Sold for($)', hupPm: 'Profit Margin($)', accHupNumber: 'Accessories Numbers', accHupNumRate: 'Accessories Numbers Rate(%)' }];
            const hupOutput = filteredData.map(({ hup, hupCount, hupARate, hupSum, hupSf, hupPm, accHupNumber, accHupNumRate }) => ({
                hup,
                hupCount,
                hupARate,
                ...hupSum,
                hupSf,
                hupPm,
                accHupNumber,
                accHupNumRate
            }));
            const keysToRoundHup = [
                'accHupNumRate', 'cost', 'discount', 'hupARate', 'hupPm', 'hupSf', 'msrp'
            ];
            const roundedDataHup = roundValues(hupOutput, keysToRoundHup);
            const actPPPKeys = [{ act: 'Act', actPPP: 'Act with PPP', actPPPRate: 'PPP Rate(%)' }];
            const actPPPOutput = filteredData.map(({ act, actPPP, actPPPRate }) => ({ act, actPPP, actPPPRate }));
            const keysToRoundActPPP = ['actPPPRate'];
            const roundedDataActPPP = roundValues(actPPPOutput, keysToRoundActPPP);
            const hupPPPKeys = [{ hup: 'Hup', hupPPP: 'Hup with PPP', hupPPPRate: 'PPP Rate(%)' }];
            const hupPPPOutput = filteredData.map(({ hup, hupPPP, hupPPPRate }) => ({ hup, hupPPP, hupPPPRate }));
            const keysToRoundHupPPP = ['hupPPPRate'];
            const roundedDataHupPPP = roundValues(hupPPPOutput, keysToRoundHupPPP);
            const actWDKeys = [{ act: 'Act', actWD: 'Act with Device', actWDRate: 'Device Rate(%)' }]
            const actWDOutput = filteredData.map(({ act, actWD, actWDRate }) => ({ act, actWD, actWDRate }));
            const keysToRoundActWD = ['actWDRate'];
            const roundedDataActWD = roundValues(actWDOutput, keysToRoundActWD);

            return { data1, actKeys, roundedDataAct, hupKeys, roundedDataHup, actPPPKeys, roundedDataActPPP, hupPPPKeys, roundedDataHupPPP, actWDKeys, roundedDataActWD };
        });
        const combinedData = []
        Object.values(outputs).forEach(output => {
            Object.values(output).forEach(obj => {
                combinedData.push(Object.values(...obj));
            })
        })
        const csvContent = "data:text/csv;charset=utf-8," +
            combinedData.map(row => Object.values(row).join(",")).join("\n");

        const [startMonth, startYear] = startDate.split('-');
        const storeNames = [...new Set(data.map(item => item.storeName))]; // Assume store names are part of the structure
        const storeName = storeNames.length === 1 ? storeNames[0] : "Total"; // Use store name if only one store
        // Check if there's only one email
        let fileName = '';
        if (uniqueEmails.length === 1) {
            const firstEmail = uniqueEmails[0];
            const emailFirstName = firstEmail.split('@')[0]; // Get the first part of the email
            fileName = `${emailFirstName}_${startMonth}_${startYear}.csv`;
        } else if (storeName) {
            fileName = `${storeNameList[storeName]}_${startMonth}_${startYear}.csv`; // Use store name if only one store
        } else {
            fileName = `${startMonth}_${startYear}.csv`; // Default to date if multiple emails or stores
        }
        // Create a link element and trigger the download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        // Update startDate and endDate when selectedMonth or selectedYear changes
        const selectedMonthIndex = months.findIndex((month) => month === selectedMonth);
        const selectedDate = new Date(selectedYear, selectedMonthIndex);

        setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
        if (selectedMonth === format(new Date(), 'MMMM') && selectedYear === new Date().getFullYear()) {
            // If current month and year, set endDate to today
            setEndDate(format(new Date(), 'yyyy-MM-dd'));
        } else {
            // Otherwise, set endDate to the last day of the selected month
            setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
        }
    }, [selectedMonth, selectedYear, months]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <div className="ar-main">
            {userDetail?.role.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            <div className="ar-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="ar-block">
                    <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 1000 }} gutter={4}></Toaster>
                    <div className="ar-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="monthSelect">Select Month:</label>
                                    <Select
                                        id="monthSelect"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {months.map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <label htmlFor="yearSelect" style={{ marginLeft: '10px' }}>Select Year:</label>
                                    <Select
                                        id="yearSelect"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {years.map((year, index) => (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </div>

                            {finalARData.length > 0 && (
                                <>
                                    {userDetail?.role !== "employee" &&
                                        (
                                            <div className='filter-menu'>
                                                <div className='filter-datemenu'>
                                                    <FormControl fullWidth>
                                                        <InputLabel>Select Employee</InputLabel>
                                                        <Select
                                                            id="employeeFilter"
                                                            value={employeName}
                                                            onChange={handleEmployeeNameChange}
                                                            className='filter-datemenu-select'
                                                            label="Select Employee"
                                                        >
                                                            <MenuItem value="">
                                                                <em>All Employee</em>
                                                            </MenuItem>
                                                            {filterEmp && filterEmp?.map((name, index) => (
                                                                <MenuItem key={index} value={name}>
                                                                    {capitalizeFirstLetter(name)}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )}
                                    <div className='filter-menu'>
                                        <div className='filter-datemenu'>
                                            <FormControl fullWidth>
                                                <InputLabel id="store-label">Select Store</InputLabel>
                                                <Select
                                                    labelId='store-label'
                                                    id="storeFilter"
                                                    value={storeName}
                                                    onChange={handleStoreNameChange}
                                                    className='filter-datemenu-select'
                                                    label="Select Store:"
                                                >
                                                    <MenuItem value="">
                                                        <em>All Stores</em>
                                                    </MenuItem>
                                                    {storesList && storesList?.map((name, index) => (
                                                        <MenuItem key={index} value={name}>
                                                            {storeNameList[name]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                    </div>
                                    <div className='topbuttons'>
                                        <Button variant="contained" color="success" onClick={downloadCSV}>
                                            Download
                                            {/* <IconButton sx={{ ml: 0 }} onClick={downloadCSV} > */}
                                            <DownloadIcon />
                                            {/*  </IconButton> */}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                        {isLoading ? (
                            <div className="loading-overlay">
                                <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                            </div>
                        ) : (

                            <div className='table'>
                                {Object.entries(aRArray1).map(([name, detail], index) => (

                                    <div className="table-container" key={index}>
                                        <div className='emp'>
                                            <span>{capitalizeFirstLetter(name)}</span>
                                        </div>
                                        <div className='emp-table'>
                                            <div className='table-act'>
                                                <table className="professional-table" >
                                                    <thead>
                                                        <tr>
                                                            {headers.map((header, index) => (
                                                                <Tooltip
                                                                    key={index}
                                                                    title={isScrolling ? '' : `${header.label}`}
                                                                    arrow
                                                                    placement="top"
                                                                >
                                                                    <th>{header.key}</th>
                                                                </Tooltip>
                                                            ))}
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>{detail?.act}</td>
                                                            <td>{detail?.actCount}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actARate?.toFixed(2)}%</td>
                                                            <td>${detail?.actSum?.discount?.toFixed(2)}</td>
                                                            {userDetail?.role === 'admin' ? (<td>${detail?.actPm?.toFixed(2)}</td>) : ""}
                                                            <td>{detail?.accActNumber}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.accActNumRate?.toFixed(2) || 0}</td>
                                                            <td>{detail?.actPPP}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actPPPRate?.toFixed(2) || 0}%</td>
                                                            <td>{detail?.actWD}</td>
                                                            <td>{detail?.act === 0 ? 0 : detail?.actWDRate?.toFixed(2)}%</td>
                                                            <td>{detail?.hours?.toFixed(2)}</td>
                                                            <td>{detail?.actperH?.toFixed(2)}</td>
                                                            <td>${detail?.OAplusComm?.toFixed(2)}</td>
                                                            <td style={{ backgroundColor: 'yellow' }}>${detail?.OAPlusrevperH && isFinite(detail?.OAPlusrevperH) ? detail?.OAPlusrevperH?.toFixed(2) : 0}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='table-hup'>

                                                <table className="professional-table">
                                                    <thead>
                                                        <tr>
                                                            {headersbot.map((header, index) => (
                                                                <Tooltip
                                                                    key={index}
                                                                    title={isScrolling ? '' : `${header.label}`}
                                                                    arrow
                                                                    placement="top"
                                                                >
                                                                    <th>{header.key}</th>
                                                                </Tooltip>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>{detail?.hup}</td>
                                                            <td>{detail?.hupCount}</td>
                                                            <td>{detail?.hup === 0 ? 0 : detail?.hupARate?.toFixed(2)}%</td>
                                                            <td>${detail?.hupSum?.discount?.toFixed(2)}</td>
                                                            {userDetail?.role === 'admin' ? (<td>${detail?.hupPm?.toFixed(2)}</td>) : ""}
                                                            <td>{detail?.accHupNumber}</td>
                                                            <td>{detail?.hup === 0 ? 0 : detail?.accHupNumRate?.toFixed(2)}</td>
                                                            <td>{detail?.hupPPP}</td>
                                                            <td>{detail?.hup === 0 ? 0 : detail?.hup === 0 ? 0 : detail?.hupPPPRate?.toFixed(2)}%</td>
                                                            <td>{detail?.OAtotal ? detail?.OAtotal : 0}</td>
                                                            {userDetail?.role === 'admin' ? (<td>${detail?.OAPM ? detail?.OAPM?.toFixed(2) : 0}</td>) : ""}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div>

                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttachRate