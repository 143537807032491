import React, { useState, useEffect } from 'react'
import './Sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import logo from '../../assets/logomedium.png';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store/store';
import { getUser } from '../../helper/helper'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';


const Sidebar = () => {

  const { username } = useAuthStore(state => state.auth);
  const [userData, setUserData] = useState('');
  const [openSection, setOpenSection] = useState(null);
  const [selectedRoute, setselectedRoute] = useState('');
  const [clickedButton, setClickedButton] = useState(null);

  let accordionSections = [
    {
      title: 'Home',
      links: [
        { label: 'Dashboard', path: selectedRoute, },
        { label: 'View Attach Rate', path: '/viewar' }
      ],
    },
    {
      title: 'Upload',
      links: [
        { label: 'CIS', path: '/cisupload', },
        { label: 'Refunds', path: '/refund', },
        { label: 'Home Internet', path: '/homeinternet' },
        { label: 'Event CIS', path: '/eventcis' },
      ],
    },

    {
      title: 'Commission',
      links: [
        { label: 'Structure', path: '/commissionstructure', },
        /* { label: 'Report', path: '/employeeCommissionReport', }, */

      ],
    },
  ];
  useEffect(() => {
    let userdata = getUser({ username: username });
    userdata.then(res => {
      let apiData = res.data;
      setUserData(apiData)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  if (userData?.role === 'manager') {
    accordionSections.push({
      title: 'Performance',
      links: [
        { label: 'Ranking', path: '/rankingPage' },
        { label: 'Attach Rate', path: '/attachrate' },
      ]
    })
  }
  if (userData?.role === 'employee') {
    accordionSections.push({
      title: 'Performance',
      links: [
        { label: 'Attach Rate', path: '/attachrate' },
      ]
    })
  }

  const handleSectionClick = (index, title) => {
    setOpenSection(index === openSection ? null : index);
    if (userData?.role === 'manager' && title === "Home") {
      setselectedRoute('/manager');
    } else if (userData?.role === 'employee' && title === "Home") {
      setselectedRoute('/employee');
    }
    setClickedButton(index);
  };



  return (
    <div className='sidebar'>
      <div className="top">
        <span className="logo"><img src={logo} alt="logo" />
        </span>
      </div>

      <div className="center">

        {accordionSections.map((section, index) => (
          <div key={index} className={`accordion-section ${openSection === index ? 'open' : ''}`}>
            <div
              className={`accordion-title ${clickedButton === index ? 'clicked' : ''}`}
              onClick={() => handleSectionClick(index, section.title)}

            >
              <div className='left'>
                {section.title === 'Home' && (<DashboardIcon />)}
                {section.title === 'Upload' && (<FileUploadIcon />)}
                {section.title === 'Commission' && (<MonetizationOnIcon />)}
                {section.title === 'Performance' && (<MilitaryTechIcon />)}
                {section.title}
              </div>

              <div className="arrow-icon ">
                {openSection === index ? <KeyboardDoubleArrowUpOutlinedIcon /> : <KeyboardDoubleArrowDownOutlinedIcon />}
              </div>
            </div>
            {openSection === index && (
              <div className="accordion-links">
                {section.links.map((link, linkIndex) => (
                  <div key={linkIndex} className="accordion-link">
                    <Link to={link.path}>{link.label}</Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

      </div>
    </div>
  )
}

export default Sidebar