import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useAuthStore } from '../../store/store'
import { generateOTP, verifyOTP } from '../../helper/helper';
import { useNavigate } from 'react-router-dom'
import './Recovery.scss';
import logo from '../../assets/logo.png';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const Recovery = () => {

    const { username } = useAuthStore(state => state.auth);
    const [OTP, setOTP] = useState();
    const navigate = useNavigate()
    

    useEffect(() => {
        generateOTP(username).then((OTP) => {
            console.log(OTP)
            if (OTP) return toast.success('OTP has been send to your email!');
            return toast.error('Problem while generating OTP!')
        })
    }, [username]);

    async function onSubmit(e) {
        e.preventDefault();
        try {
            let { status } = await verifyOTP({ username, code: OTP })
            if (status === 201) {
                toast.success('Verify Successfully!')
                return navigate('/reset')
            }
        } catch (error) {
            return toast.error('Wrong OTP! Check email again!',
                {
                    id: 'wrongOTP',
                    duration: 3000,
                    style: {
                        border: '2px solid red',
                        fontSize: '16px',
                        fontWeight: '480',
                        padding: '6px',
                        borderRadius: "10px",
                    }
                })  
        }
    }

    // handler of resend OTP
    function resendOTP() {

        let sentPromise = generateOTP(username);

        toast.promise(sentPromise,
            {
                loading: 'Sending...',
                success: <b>OTP has been send to your email!</b>,
                error: <b>Could not Send it!</b>,
            }
        );

        sentPromise.then((OTP) => {

        });

    }

    return (
            
        <div className='recovery_container'>
            <Toaster position='top-center' reverseOrder={false}></Toaster>
                <div className="left">
                <div className="overlay">
                    <div className='bot'>
                        <h1>VFLOW</h1>
                        <div className='logo'>
                            <p>Powered by</p>
                            <img src={logo} alt="logo" />
                            <div className='footer'>
                                <p>Copyright © 2010 - 2024 Vyadom Group. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  <form className="right" onSubmit={onSubmit} >
                <div className='main'>
                    <h3 className='heading'>Forgot Password</h3>
                         <span className='middle-span'>
                                Enter 6 digit OTP code sent to your email address.
                        </span>
                         <input  type="text" className='middle-input' placeholder="OTP Code"  onChange={(e) => setOTP(e.target.value)} />
                   
                    <div className='submitbutton'>
                        <button type='submit'>Verify And Continue</button>
                    </div>

                    <div className="bottom">
                        <span className='bottom-span'>Didn't Receive an OTP? &nbsp;&nbsp;
                            <Button onClick={resendOTP} size="small" variant="outlined" color="primary"
                                endIcon={<SendIcon />} disableElevation>Resend Code</Button>
                        </span>
            
                    </div>
                </div>
            </form>
            
        </div>
    )
}

export default Recovery