import React, { useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { sumbitManualSkuList } from '../../helper/helper'
const AccessorySkuModal = ({ modalIsOpen, closeModal }) => {
    const [manualFile, setManualFile] = useState([]);
    if (!modalIsOpen) {
        return null;
    }
    const handleFileUpload = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // Extracting store names
            const stores = excelData.slice(1,);
            setManualFile(stores)
        };
        reader.readAsArrayBuffer(file);
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (manualFile.length > 0) {
            let promise = sumbitManualSkuList({ manualFile });
            toast.promise(promise, {
                loading: 'Submitting...',
                success: <b>Submitted Successfully...!</b>,
                error: <b>Could not Submit.</b>
            })
        } else {
            toast.error("Please upload a file!")
        }
    }
    return (
        <div className="hi-modal">
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className="modal-content">
                <div className="close-button">
                    <button onClick={closeModal}>✖</button>
                </div>
                <div className='top'>
                    {/* for Home internet upload */}
                    <div className='block'>
                        <div className="top-block">
                            <h4 >Upload Accessory-SKU file</h4>
                            <span>
                                Select one file at a time.
                            </span>
                        </div>
                        <div className='bottom-block'>
                            <input onChange={handleFileUpload} multiple={false} type="file" id='cis' name='cis' />
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <button onClick={onSubmit} className='sub-btn' type='submit'>Upload</button>
                </div>
            </div>
        </div>
    )
}
export default AccessorySkuModal