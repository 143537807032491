import React, { useState, useEffect } from 'react'
import './ViewAR.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import { useAuthStore } from '../../store/store';
import Sidebar from '../../components/Sidebar/Sidebar'
import ReactLoading from 'react-loading';
import { getAttachRate, getOnlyAccessory, getUser, getAllUsersData } from '../../helper/helper';
import Tooltip from '@mui/material/Tooltip';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import Button from '@mui/material/Button';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const ViewAR = () => {
    const { username } = useAuthStore(state => state.auth);
    const [userList, setuserList] = useState([])
    const [userdata, setUserdata] = useState([]);
    const [userDetail, setUserDetail] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [filterEmp, setFilterEmp] = useState('');
    const [aRArray, setARArray] = useState([])
    const [aRArray1, setARArray1] = useState([])
    const [employeName, setEmployeName] = useState("");

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getAllUsersData();
            setuserList(data)
        }
        fetchUser();
    }, [username])

    /* const currentDate = new Date();
    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // Calculate the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // Format the first and last day as strings (YYYY-MM-DD)
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0]; */
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const generateMonths = () => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 5; i++) { // Generate the last 5 years
            years.push(currentYear + i);
        }
        return years;
    };

    const months = generateMonths();
    const years = generateYears();
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update startDate and endDate when selectedMonth or selectedYear changes
        const selectedMonthIndex = months.findIndex((month) => month === selectedMonth);
        const selectedDate = new Date(selectedYear, selectedMonthIndex);

        setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
        if (selectedMonth === format(new Date(), 'MMMM') && selectedYear === new Date().getFullYear()) {
            // If current month and year, set endDate to today
            setEndDate(format(new Date(), 'yyyy-MM-dd'));
        } else {
            // Otherwise, set endDate to the last day of the selected month
            setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
        }
    }, [selectedMonth, selectedYear, months]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    /* function removeCopies(result) {
        const uniqueItems = {};

        result.forEach(([id, name, weight, price]) => {
            if (uniqueItems[id]) {
                uniqueItems[id][2] += weight;  // Add to the weight
                uniqueItems[id][3] += 1;  // Increment the count
            } else {
                uniqueItems[id] = [id, name, weight, 1, price];
            }
        });

        // Convert the map back to an array
        return Object.values(uniqueItems);
    } */

    const handleSearch = async () => {
        setIsLoading(true);
        let result = []
        let result1 = []
        try {
            const { data } = await getAttachRate({ startDate, endDate });
            const removeDuplicatesByInvoice = (array) => {
                const seenInvoices = new Set();
                return array.filter(item => {
                    if (!item.saleInvoice) {
                        return true; // Keep objects without saleInvoice
                    }
                    const duplicate = seenInvoices.has(item.saleInvoice);
                    seenInvoices.add(item.saleInvoice);
                    return !duplicate;
                });
            };
            const uniqueArray = removeDuplicatesByInvoice(data);
            const modifiedData = uniqueArray.map(item => ({
                ...item,
                attachedAcc: item.attachedAcc.map(entry => [
                    ...entry,
                    item.tenderedDate
                ])
            }));
            if (modifiedData?.length > 0) {
                if (userDetail.role === 'manager') {
                    let finalArray = []
                    userDetail?.stores?.split(',').forEach(store => {
                        userList.forEach(user => {
                            let exist = user.stores.split(',').some(item => item === store)
                            if (exist) {
                                if (!finalArray.includes(user)) {
                                    finalArray.push(user)
                                }
                            }
                        })
                    })
                    setUserdata(finalArray);
                    let storesList = userDetail?.stores?.split(',')
                    let employeeList = finalArray?.map(emp => emp.username);
                    setFilterEmp(employeeList);

                    let filterAR = []
                    storesList.forEach(store => {
                        modifiedData.forEach(obj => {
                            const { storeInvoice } = obj;
                            if (storeInvoice === store) {
                                filterAR.push(obj)
                            }
                        })
                    })
                    dataProcess(filterAR)
                } else if (userDetail.role === 'admin') {

                    dataProcess(modifiedData)
                    let filterEmployee = []
                    let employeeList = modifiedData?.map(emp => emp.user);
                    employeeList?.forEach(val => {
                        let isExist = filterEmployee.some(employee => employee === val);
                        if (!isExist) filterEmployee.push(val);
                    })
                    setFilterEmp(filterEmployee);
                } else if (userDetail.role === 'employee') {
                    let filterAR = []
                    modifiedData.forEach(obj => {
                        if (obj.user === userDetail?.username) {
                            filterAR.push(obj)
                        }
                    })
                    dataProcess(filterAR)
                }
            }

            function dataProcess(uniqueArray) {
                const groupData = (data) => {
                    return data.reduce((acc, item) => {
                        const { user, attachedAcc, aaData: { msrp, discount } } = item;
                        if (!acc[user]) {
                            acc[user] = {
                                user,
                                attachedAcc: [],
                                totalMsrp: 0,
                                totalDiscount: 0,
                            };
                        }
                        acc[user].attachedAcc = [...acc[user].attachedAcc, ...attachedAcc];
                        acc[user].totalMsrp += msrp;
                        acc[user].totalDiscount += discount;
                        return acc;
                    }, {});
                };
                const groupedData = groupData(uniqueArray);
                result = Object.values(groupedData).map(item => ({
                    ...item,
                    totalMsrpMinusDiscount: item.totalMsrp - item.totalDiscount
                }));
            }
        } catch (error) {
        }
        try {
            let { data } = await getOnlyAccessory({ startDate, endDate });
            const removeDuplicatesByInvoice = (array) => {
                const seenInvoices = new Set();
                return array.filter(item => {
                    if (!item.saleInvoiceOA) {
                        return true; // Keep objects without saleInvoice
                    }
                    const duplicate = seenInvoices.has(item.saleInvoiceOA);
                    seenInvoices.add(item.saleInvoiceOA);
                    return !duplicate;
                });
            };

            let filtered = [];
            data.forEach(obj => {
                filtered.push(obj.attachmentRateOnlyAccessory)
            })
            const uniqueArray = removeDuplicatesByInvoice(filtered);

            const modifiedData = uniqueArray.map(item => ({
                ...item,
                attachedAccOnlyAccessory: item.attachedAccOnlyAccessory.map(entry => [
                    ...entry,
                    item.tenderedDateOA
                ])
            }));

            if (modifiedData.length > 0) {
                if (userDetail.role === 'manager') {
                    let storesList = userDetail?.stores?.split(',')
                    let matchingInvoices = modifiedData.filter(item => {
                        // Extract the prefix part of the saleInvoiceOA
                        let prefix = '';
                        if (item.saleInvoiceOA !== '') {
                            prefix = item?.saleInvoiceOA.match(/^[a-zA-Z]+/)[0];
                        }
                        /* let prefix = item.saleInvoiceOA.match(/^[a-zA-Z]+/)[0]; */
                        // Check if the extracted prefix matches any prefix in array2
                        return storesList.some(prefixInArray => prefix === prefixInArray);
                    }).map(item => item);
                    dataProcess(matchingInvoices)

                } else if (userDetail.role === 'admin') {
                    dataProcess(modifiedData)

                } else if (userDetail.role === 'employee') {
                    let filterAR = []
                    modifiedData.forEach(obj => {
                        if (obj.username === userDetail?.username) {
                            filterAR.push(obj)
                        }
                    })
                    dataProcess(filterAR)
                }
            }

            function dataProcess(filtered) {
                const groupData = (data) => {
                    return data.reduce((acc, item) => {
                        const { username, attachedAccOnlyAccessory, aaDataOnlyAccessory: { msrp, discount } } = item;
                        if (!acc[username]) {
                            acc[username] = {
                                username,
                                attachedAcc: [],
                                totalMsrp: 0,
                                totalDiscount: 0,
                            };
                        }
                        acc[username].attachedAcc = [...acc[username].attachedAcc, ...attachedAccOnlyAccessory];
                        acc[username].totalMsrp += msrp;
                        acc[username].totalDiscount += discount;
                        return acc;
                    }, {});
                };

                // Grouped data
                const groupedData = groupData(filtered);

                // Calculate total MSRP minus discount for each specialist
                result1 = Object.values(groupedData).map(item => ({
                    ...item,
                    totalMsrpMinusDiscount: item.totalMsrp - item.totalDiscount
                }));


            }
        } catch (error) {
        }

        const mergedData = mergeUserData(result, result1);
        /* console.log(result, result1, mergedData) */
        if (mergedData) {
            setIsLoading(false)
        }
        setARArray(mergedData)
        setARArray1(mergedData)
    };



    const mergeUserData = (list1, list2) => {
        // Create a map for quick lookup of users in list2 by username
        const userMap = list2.reduce((acc, userObj2) => {
            acc[userObj2.username] = userObj2;
            return acc;
        }, {});

        // Create a new list to store the merged data
        const mergedData = [];

        // Iterate through list1 and merge data if there's a matching user in list2
        list1.forEach(userObj1 => {
            const matchingUser = userMap[userObj1.user];
            if (matchingUser) {
                mergedData.push({
                    user: userObj1.user,
                    attachedAcc: {
                        list1: /* removeCopies(userObj1.attachedAcc) */userObj1.attachedAcc,
                        list2:/*  removeCopies(matchingUser.attachedAcc) */matchingUser.attachedAcc
                    },

                    totalMsrpMinusDiscount: {
                        list1: userObj1.totalMsrpMinusDiscount,
                        list2: matchingUser.totalMsrpMinusDiscount
                    }
                });
                // Remove the matched user from the map to avoid duplicates
                delete userMap[userObj1.user];
            } else {
                mergedData.push({
                    user: userObj1.user,
                    attachedAcc: {
                        list1: /* removeCopies(userObj1.attachedAcc) */userObj1.attachedAcc,
                        list2: []
                    },
                    totalMsrpMinusDiscount: {
                        list1: userObj1.totalMsrpMinusDiscount,
                        list2: 0
                    }
                });
            }
        });
        return mergedData;
    };

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton" || "Center Mall at Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 7) {
            return storeNameList[string]
        } else {
            let user = []
            for (let key in userdata) {
                if (userdata[key].username === string) {
                    user.push(userdata[key].fullname)
                }
            }
            return user.length > 0 ? user[0] : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11)
        }

    };

    const handleEmployeeNameChange = (e) => {
        e.preventDefault();
        setEmployeName(e.target.value)
        const { value } = e.target
        if (value !== "") {
            let obj = [];
            aRArray.forEach(emp => {
                if (emp.user === value) {
                    obj.push(emp)
                }
            })
            setARArray1(obj);
        } else {
            setARArray1(aRArray);
        }
    }

    const headers = [
        { key: 'sku', label: 'SKU' },
        { key: 'name', label: 'Accessory Name' },
        /* { key: 'quantity', label: 'Quantity' }, */
        { key: 'date', label: 'Date' },
    ]

    return (
        <div className="var-main">
            {userDetail?.role.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            <div className="var-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="var-block">
                    <div className="var-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="monthSelect">Select Month:</label>
                                    <Select
                                        id="monthSelect"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {months.map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <label htmlFor="yearSelect" style={{ marginLeft: '10px' }}>Select Year:</label>
                                    <Select
                                        id="yearSelect"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {years.map((year, index) => (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </div>
                            {/* <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="startDate">From:</label>
                                    <input
                                        type="date"
                                        id="startDate"
                                        className='filter-datemenu-input'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <label htmlFor="endDate">To:</label>
                                    <input
                                        type="date"
                                        id="endDate"
                                        className='filter-datemenu-input'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                    <button className='filter-datemenu-button' onClick={handleSearch}>Search</button>
                                </div>
                            </div> */}
                            {userDetail?.role !== "employee" && filterEmp.length > 0 &&
                                (
                                    <div className='filter-menu'>
                                        <div className='filter-datemenu'>
                                            <FormControl fullWidth>
                                                <InputLabel>Select Employee</InputLabel>
                                                <Select
                                                    id="employeeFilter"
                                                    value={employeName}
                                                    onChange={handleEmployeeNameChange}
                                                    className='filter-datemenu-select'
                                                    label="Select Employee"
                                                >
                                                    {filterEmp && filterEmp?.map((name, index) => (
                                                        <MenuItem key={index} value={name}>
                                                            {capitalizeFirstLetter(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                )}
                        </div>
                        <div>
                            {isLoading ? (
                                <div className="loading-overlay">
                                    <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                                </div>
                            ) : (

                                <div className="tables">
                                    {aRArray1?.map((detail, index) => (
                                        <div className='tables-container' key={index}>
                                            <div className='table' key={index}>
                                                {/* <span>Attached Accessories</span> */}
                                                {detail.totalMsrpMinusDiscount.list1 !== 0 && (
                                                    <div className="table-container" key={index}>
                                                        <div className='emp'>
                                                            <span>{capitalizeFirstLetter(detail.user)} - Attached Accessories</span>
                                                            <span>Revenue: ${detail.totalMsrpMinusDiscount.list1.toFixed(2)}</span>
                                                        </div>
                                                        <div className='table-acc' key={index}>
                                                            <table className="professional-table" key={index} >
                                                                <thead>
                                                                    <tr>
                                                                        {headers.map(header => (
                                                                            <Tooltip key={header.key} title={`${header.label}`} arrow placement="top">
                                                                                <th>{header.label}</th>
                                                                            </Tooltip>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                {detail.attachedAcc.list1.map((acc, index) => (
                                                                    <tbody >
                                                                        <tr key={index}>
                                                                            <td>{acc[0]}</td>
                                                                            <td>{acc[1]}</td>
                                                                            {/* <td>{acc[3]}</td> */}
                                                                            <td>{acc[4]}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                ))}
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='table'>
                                                {detail.totalMsrpMinusDiscount.list2 !== 0 && (
                                                    <div className="table-container" key={index}>
                                                        <div className='emp'>
                                                            <span>{capitalizeFirstLetter(detail.user)} - Only Accessories</span>
                                                            <span>Revenue: ${detail.totalMsrpMinusDiscount?.list2?.toFixed(2)} </span>
                                                        </div>
                                                        <div className='table-acc'>
                                                            <table className="professional-table" key={index} >
                                                                <thead>
                                                                    <tr>
                                                                        {headers.map(header => (
                                                                            <Tooltip key={header.key} title={`${header.label}`} arrow placement="top">
                                                                                <th>{header.label}</th>
                                                                            </Tooltip>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                {detail.attachedAcc?.list2.map((acc, index) => (
                                                                    <tbody >
                                                                        <tr key={index}>
                                                                            <td>{acc[0]}</td>
                                                                            <td>{acc[1]}</td>
                                                                            {/*  <td>{acc[3]}</td> */}
                                                                            <td>{acc[4]}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                ))}
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAR