import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const readOnlyRows = ({ target, handleEditEmployeeTarget, handleDeleteClick }) => {
    return (
        <tr>
            <td>{target.employeename}</td>
            <td>{target.storename}</td>
            <td>{target.prepaidtarget}</td>
            <td>{target.postpaidtarget}</td>
            <td>{target.hardwareupgrade}</td>
            <td>{target.addon}</td>
            <td>{parseInt(target.accessories).toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td>{target.applecare}</td>
            <td>{target.smssurvey}</td>
            <td>
                
                <IconButton aria-label="delete" size="large" onClick={(event) => handleEditEmployeeTarget(event, target)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="delete" size="large" onClick={() => handleDeleteClick(target._id)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </td>
        </tr>
    )
}

export default readOnlyRows