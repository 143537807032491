import React from 'react'
import './Clockin.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import Pb2 from '../../components/Pb2'

const Clockin = () => {
    return (
        <div className="clockin-main">
            <AdminSidebar />
            <div className="clockin-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="clockin-block">
                    <div className="clockin-block-container">
                        <Pb2 />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clockin