import React, { useState, useEffect } from 'react'
import './EmpHours.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import ReactLoading from 'react-loading';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadModal from './UploadModal';
import { getHoursWorkedData } from '../../helper/helper'
import { MenuItem, Select } from '@mui/material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import Button from '@mui/material/Button';
import toast, { Toaster } from 'react-hot-toast';

const EmpHours = () => {
    /* const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0]; */

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [apiData, setApiData] = useState([]);
    /*  const currentMonthFormatted = format(new Date(), 'MMMM yyyy');
     const [selectedMonth, setSelectedMonth] = useState(currentMonthFormatted); */
    const generateMonths = () => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 5; i++) { // Generate the last 5 years
            years.push(currentYear + i);
        }
        return years;
    };

    const months = generateMonths();
    const years = generateYears();
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update startDate and endDate when selectedMonth or selectedYear changes
        const selectedMonthIndex = months.findIndex((month) => month === selectedMonth);
        const selectedDate = new Date(selectedYear, selectedMonthIndex);
        setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
        setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
        /*  setStartDate(format(startOfMonth(selectedDate), 'yyyy-MM-dd'));
         if (selectedMonth === format(new Date(), 'MMMM') && selectedYear === new Date().getFullYear()) {
             // If current month and year, set endDate to today
             setEndDate(format(new Date(), 'yyyy-MM-dd'));
         } else {
             // Otherwise, set endDate to the last day of the selected month
             setEndDate(format(endOfMonth(selectedDate), 'yyyy-MM-dd'));
         } */
    }, [selectedMonth, selectedYear, months]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSearch = async () => {
        setIsLoading(true);
        const { data } = await getHoursWorkedData({ startDate, endDate });
        console.log(data);
        if (data.length > 0) {
            setApiData(data);
        } else {
            toast.error("No Employee Hours Data is Present to Show.")
        }
    }


    if (isLoading && apiData?.length > 0) {
        setIsLoading(false); // Stop loading if data is fetched
    }

    /* const generateMonths = () => {
        const months = [];
        const currentMonth = new Date();
        for (let i = 0; i < 6; i++) { // Generate the last 6 months
            const monthDate = subMonths(currentMonth, i);
            months.push(format(monthDate, 'MMMM yyyy')); // e.g., November 2024
        }
        return months;
    };

    const months = generateMonths(); // Pre-generate months (November, October, etc.)

    useEffect(() => {
        // Update startDate and endDate when selectedMonth changes
        const monthIndex = months.findIndex((month) => month === selectedMonth);
        const selectedMonthDate = subMonths(new Date(), monthIndex);

        setStartDate(format(startOfMonth(selectedMonthDate), 'yyyy-MM-dd'));
        if (selectedMonth === months[0]) {
            // If current month, set endDate to today
            setEndDate(format(new Date(), 'yyyy-MM-dd'));
        } else {
            // Otherwise, set endDate to the last day of the month
            setEndDate(format(endOfMonth(selectedMonthDate), 'yyyy-MM-dd'));
        }
    }, [selectedMonth, months, startDate, endDate]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    } */


    const StoreTable = ({ storeHours, empHours }) => {

        const sortedDataStore = storeHours?.sort((a, b) => b[1] - a[1]);
        const sortedDataEmp = empHours?.sort((a, b) => b[1] - a[1]);
        return (
            <>
                <div className='storeTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Store</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDataStore?.map((store, index) => (
                                <tr key={index}>
                                    <td>{store[0]}</td>
                                    <td>{store[1]?.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="empTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDataEmp?.map((emp, index) => (
                                <tr key={index}>
                                    <td>{emp[0]}</td>
                                    <td>{emp[1]?.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };
    return (
        <div className="eh-main">
            <AdminSidebar />
            <div className="eh-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="eh-block">
                    <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 1000 }} gutter={4}></Toaster>
                    <div className="eh-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="monthSelect">Select Month:</label>
                                    <Select
                                        id="monthSelect"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {months.map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <label htmlFor="yearSelect" style={{ marginLeft: '10px' }}>Select Year:</label>
                                    <Select
                                        id="yearSelect"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        {years.map((year, index) => (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="upload-button-container">
                                <UploadModal
                                    modalIsOpen={modalIsOpen}
                                    closeModal={closeModal}
                                />
                                {/* <Tooltip title="Upload Hours File" arrow placement="top">
                                    <IconButton sx={{ ml: 2 }} onClick={openModal}>
                                        <FileUploadIcon />
                                    </IconButton>
                                </Tooltip> */}
                                <Button variant="contained" color="success" onClick={openModal}>
                                    Upload Hours File
                                    <FileUploadIcon />
                                </Button>
                            </div>
                        </div>
                        {apiData.length > 0 && (

                            <div className="datatable">
                                <StoreTable storeHours={apiData[0]?.storeHours.filter(item => item[0] !== 'undefined' && item[1] !== null)} empHours={apiData[0]?.empHours.filter(item => item[0] !== 'undefined' && item[1] !== null)} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmpHours