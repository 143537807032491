import React, { useState, useEffect } from 'react'
import './EventModal.scss'
import toast, { Toaster } from "react-hot-toast";
import { useAuthStore } from '../../store/store';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllUsersData, submitEvent } from '../../helper/helper';

const EventModal = ({ modalIsOpen, closeModal }) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState(formattedDate);
    const [selectedRep, setSelectedRep] = useState('');
    const [reps, setReps] = useState([]); // This should be dynamic in a real scenario
    const [addedReps, setAddedReps] = useState([]);
    const { username } = useAuthStore(state => state.auth);

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getAllUsersData();
            let empList = [];
            data.forEach(emp => {
                if (emp.username !== "admin@vyadom.com") {
                    empList.push(emp.fullname)
                }
            })
            setReps(empList);
        }
        fetchUser();
    }, [username])

    if (!modalIsOpen) {
        return null;
    }

    const handleAddRep = () => {
        if (selectedRep && !addedReps.includes(selectedRep)) {
            setAddedReps([...addedReps, selectedRep]);
            setSelectedRep('');
        }
    };

    const handleRemoveRep = (repToRemove) => {
        setAddedReps(addedReps.filter((rep) => rep !== repToRemove));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = {
            eventName,
            eventDate: eventDate,
            addedReps,
        };
        console.log(formData);
        let submitPromise = submitEvent(formData)
        toast.promise(submitPromise, {
            loading: 'Uploading...',
            success: <b>Uploaded Successfully...!</b>,
            error: <b>Not uploaded</b>
        })
        submitPromise.then(() => {
            closeModal();
            setEventName('');
            setEventDate(new Date());
            setAddedReps([]);
        });
    };

    return (
        <div className="es-modal">
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className="modal-content">
                <div className="close-button">
                    <button onClick={closeModal}>✖</button>
                </div>

                <div className='top'>
                    <div className="form-container">
                        <form onSubmit={handleSubmit} className="event-form">
                            <div className="form-group">
                                <label>Event Name:</label>
                                <input
                                    type="text"
                                    value={eventName}
                                    onChange={(e) => setEventName(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <label>Event Date:</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className='form-input'
                                    value={eventDate}
                                    onChange={(e) => setEventDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Rep:</label>
                                <div className="rep-select-group">
                                    <select
                                        value={selectedRep}
                                        onChange={(e) => setSelectedRep(e.target.value)}
                                        className="second-class"
                                    >
                                        <option value="">Select a rep</option>
                                        {reps.map((rep, index) => (
                                            <option key={index} value={rep}>
                                                {rep}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="button" onClick={handleAddRep} className="add-button">
                                        + Add
                                    </button>
                                </div>
                            </div>
                            <div className="added-reps">
                                {addedReps.map((rep, index) => (
                                    <div key={index} className="rep-item">
                                        {rep}<span className="remove-rep" onClick={() => handleRemoveRep(rep)}>✖</span>
                                    </div>
                                ))}
                            </div>
                            <button type="submit" className="submit-button">Submit</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EventModal