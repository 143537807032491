import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditUserModal from "./EditUserModal";
import { getValues } from '../../components/ReusableFunctions'

const ReadOnlyUserList = ({ userdata/* , handleEditEmployee */, handleDeleteClick, fetchUserList }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editEmployeeId, setEditEmployeeId] = useState(null);
    /* const [userdata, setUserdata] = useState([]) */
    const [editFormData, setEditFormData] = useState({
        fullname: '',
        username: '',
        /* password: '', */
        email: '',
        role: '',
        phonenumber: '',
        employeeid: '',
        stores: '',
        dob: '',
        employmentStatus: ''
    })
    const closeModal = () => {
        setModalIsOpen(false);
    };
    var values = getValues(userdata.stores);

    const handleEditEmployee = (event, userdata) => {
        event.preventDefault();
        setModalIsOpen(true);
        setEditEmployeeId(userdata._id);
        const formValues = {
            fullname: userdata.fullname,
            username: userdata.username,
            /*  password: userdata.password, */
            email: userdata.email,
            role: userdata.role,
            phonenumber: userdata.phonenumber,
            employeeid: userdata.employeeid,
            stores: userdata.stores,
            dob: userdata.dob,
            employmentStatus: userdata?.employmentStatus,
        }
        setEditFormData(formValues)
    }
    const handleUpdateUserList = () => {
        fetchUserList();
    };
    return (
        <>
            <tr key={userdata._id} >
                <td>{userdata.fullname}</td>
                <td>{userdata.username}</td>
                {/* <td>{userdata.password.slice(7, 1)}</td> */}
                <td>{userdata.email}</td>
                <td>{userdata.role}</td>
                <td>{userdata.phonenumber}</td>
                <td>{userdata.employeeid}</td>
                <td>{values.split(',').map((store, index) => (
                    <p key={index}>{store}</p>
                ))}</td>
                <td>{userdata.dob}</td>
                <td>{userdata.employmentStatus}</td>
                <td>
                    <IconButton aria-label="delete" size="large" onClick={(event) => handleEditEmployee(event, userdata)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                    {/* <IconButton aria-label="delete" size="large" onClick={() => handleDeleteClick(userdata._id)}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton> */}
                </td>
            </tr>
            {modalIsOpen && (
                <EditUserModal
                    modalIsOpen={modalIsOpen}
                    closeModal={closeModal}
                    editFormData={editFormData}
                    /* handleEditFormChange={setEditFormData} */
                    editEmployeeId={editEmployeeId}
                    /* handleEditFormSubmit={handleEditFormSubmit} */
                    onUpdate={handleUpdateUserList}
                />
            )}
        </>
    )
}

export default ReadOnlyUserList