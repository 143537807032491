import React, { useState, useEffect } from 'react'
import './BusinessReport.scss';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import PandLFilter from '../../components/PandLFilter'
import BRModal from '../../components/BRModal/BRModal';
import { getEmployeeCommissionReport, getManualInputData } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast'
const BusinessReport = () => {
    const [selectedStore, setSelectedStore] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [apiDataECR, setApiDataECR] = useState([]);
    const [manualData, setManualData] = useState([]);
    const [storePLData, setStorePLData] = useState([]);
    const [storeData, setStoreData] = useState(null);
    const [netProfileLoss, setNetProfileLoss] = useState(null);
    const [dateforModalInput, setDateForModalInput] = useState(null);
    useEffect(() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        const prevMonth = today.toLocaleString('default', { month: 'long' });
        const prevYear = today.getFullYear();
        const currentDate = prevMonth + ' ' + prevYear
        const data1 = getEmployeeCommissionReport(currentDate);

        data1.then((data2) => {
            setApiDataECR(data2?.data[0]);
        })

    }, []);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const endDate = lastDayOfMonth.toISOString().split('T')[0];

        const data = getManualInputData({ endDate });
        data.then((res) => {
            setManualData(res.data);
        })
    }, [])
    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    const handleAddPandLChange = (e) => {
        e.preventDefault();

        openModal();
    }
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);

    };
    const setData = (data) => {
        setStorePLData(data);
        if (data.length === 0) {
            toast.success("No P&L data available");
        } else {
            let summedExpense = {};
            let summedRevenue = {};

            // Iterate through the array of objects
            data.forEach(obj1 => {
                for (let obj in obj1.plData) {
                    // Iterate through each expense in the object and sum them up
                    for (let key in obj1.plData[obj].expense) {
                        let value = parseFloat(obj1.plData[obj].expense[key]);
                        summedExpense[key] = (summedExpense[key] || 0) + value;
                    }

                    // Iterate through each revenue in the object and sum them up
                    for (let key in obj1.plData[obj].revenue) {
                        // Parse the value as an integer before summing
                        let value = parseFloat(obj1.plData[obj].revenue[key]);
                        summedRevenue[key] = (summedRevenue[key] || 0) + value;
                    }
                }

            });

            // Convert summedExpense and summedRevenue into the desired format
            let result = {
                expense: summedExpense,
                revenue: summedRevenue
            };
            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

            setNetProfileLoss(netProfitLoss)
            setStoreData(result)
        }

    }
    const apiDataECRfunction = (data) => {
        setApiDataECR(data);
    };
    const apiAccessoryData = (data) => {
        setManualData(data);
    }
    const dateforModal = (data) => {
        setDateForModalInput(data);
    }

    const handleStoreChange = (e) => {
        e.preventDefault();
        setSelectedStore(e.target.value)
        const value = e.target.value
        let storeData = {}


        if (value !== "") {

            for (let key1 in storePLData) {

                for (let key in storePLData[key1].plData) {
                    if (key === storeNameList[value]) {
                        storeData['expense'] = storePLData[key1].plData[key].expense
                        storeData['revenue'] = storePLData[key1].plData[key].revenue
                        storeData['store'] = key
                        const totalRev = storePLData[key1].plData[key].revenue['Total Sales']
                        const totalExp = storePLData[key1].plData[key].expense['Total Expense']
                        const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
                        setNetProfileLoss(netProfitLoss)
                    }
                }
            }
            setStoreData(storeData)
        } else {
            let summedExpense = {};
            let summedRevenue = {};

            // Iterate through the array of objects
            storePLData.forEach(obj1 => {
                for (let obj in obj1.plData) {
                    // Iterate through each expense in the object and sum them up
                    for (let key in obj1.plData[obj].expense) {
                        let value = parseFloat(obj1.plData[obj].expense[key]);
                        summedExpense[key] = (summedExpense[key] || 0) + value;
                    }

                    // Iterate through each revenue in the object and sum them up
                    for (let key in obj1.plData[obj].revenue) {
                        // Parse the value as an integer before summing
                        let value = parseFloat(obj1.plData[obj].revenue[key]);
                        summedRevenue[key] = (summedRevenue[key] || 0) + value;
                    }
                }

            });

            // Convert summedExpense and summedRevenue into the desired format
            let result = {
                expense: summedExpense,
                revenue: summedRevenue
            };
            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

            setNetProfileLoss(netProfitLoss)
            setStoreData(result)

        }
    };
    function formatNegativeNumber(number) {
        // Remove any non-digit characters and convert to a number

        const numericValue = parseFloat(number?.replace(/[^0-9.-]/g, ''));

        // Check if the number is negative
        if (numericValue < 0) {
            // Format the absolute value with commas for thousands separator
            const formattedNumber = Math.abs(numericValue).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            // Return the formatted number with parentheses
            return `(${formattedNumber})`;
        }
        // If the number is not negative, return it as is
        return number;
    }
    return (
        <div className="br-main">
            <AdminSidebar />
            <div className="br-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="br-block">
                    <Toaster position='top-center' reverseOrder={false}></Toaster>
                    <div className="br-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <PandLFilter setData={setData} apiDataECRfunction={apiDataECRfunction} apiAccessoryData={apiAccessoryData} dateforModal={dateforModal} />
                            </div>

                            <div className='filter-menu'>
                                <select
                                    value={selectedStore}
                                    onChange={handleStoreChange}
                                >
                                    <option value="">All Stores</option>
                                    {Object.keys(storeNameList).map((key) => (
                                        <option key={key} value={key}>{storeNameList[key]}</option>
                                    ))}
                                </select>
                                <BRModal
                                    isOpen={modalOpen}
                                    onClose={closeModal}
                                    apiDataECR={apiDataECR}
                                    manualData={manualData}
                                    dateforModalInput={dateforModalInput}
                                />
                            </div>

                            <div className='add-button'>
                                <button onClick={handleAddPandLChange}>
                                    + Add P&L
                                </button>
                            </div>
                        </div>
                        <table className="accountant-table">
                            <thead>
                                <tr>
                                    <th>Revenue</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeData && Object.entries(storeData?.revenue).map(([category, amount]) => (
                                    <tr key={category}>
                                        <td>{category}</td>
                                        <td>
                                            <span>$</span>
                                            <span>{parseFloat(amount).toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className="accountant-table">
                            <thead>
                                <tr>
                                    <th>Expense</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeData && Object.entries(storeData?.expense).map(([category, amount]) => (
                                    <tr key={category}>
                                        <td>{category}</td>
                                        <td>
                                            <span>$</span>
                                            <span>{parseFloat(amount).toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={`net-profit-loss ${netProfileLoss < 0 ? 'negative' : 'positive'}`}>
                            <label>Net Profit/(Loss):</label>
                            <div className="total-value">
                                <span className="left-span">$</span>
                                <span>{netProfileLoss > 0 ? parseFloat(netProfileLoss).toLocaleString('en-US', { maximumFractionDigits: 2 }) : formatNegativeNumber(netProfileLoss)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BusinessReport